import React from "react";

const SubmissionsYtdTable = ({ title, data }) => {
  currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency',currency: 'USD'})
  return (
    <div className="table-container mt-2">
      {title && <h2 className="text-center">{title}</h2>}
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col" className="text-center"></th>
            <th scope="col" className="text-center">Public Submitted</th> 
            <th scope="col" className="text-center">Store Submitted</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" className="text-center">Number of Requests</th>
            <td className="text-center">{data.public_submitted.number_of_requests}</td> 
            <td className="text-center">{data.store_submitted.number_of_requests}</td>
          </tr>
          <tr>
            <th scope="row" className="text-center">Total $ of Requests</th>
            <td className="text-center">{currencyFormatter.format(data.public_submitted.dollar_amount)}</td>
            <td className="text-center">{currencyFormatter.format(data.store_submitted.dollar_amount)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SubmissionsYtdTable;