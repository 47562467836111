import responsive from "../../themes/Responsive";

const searchFormTemplate = {
  filters: {
    label: null,
    fields: [
      {
        text: "Clear",
        type: "resetButton",
        className: "btn btn-dark w-100 mb-2 py-2",
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        responsive: responsive.searchWidth,
      },
      {
        name: "email",
        type: "text",
        placeholder: "Email",
        responsive: responsive.searchWidth,
      },
    ],
  },
};

export default searchFormTemplate;
