import React from "react";
import { useField, useFormikContext } from "formik";
import moment from 'moment';

import DatePicker from "react-datepicker";

// Without Memoziation here, the datepicker will re-render when clicked outside of on the page and it will yank the screen up
const MemoDatePicker = React.memo(({ field, setFieldValue, ...props }) => (
  <DatePicker
      {...field}
      {...props}
      dateFormat="yyyy/MM/dd"
      selected={(field.value && new Date(moment(field.value)))}
      onFocus={(e) => {
        e.preventDefault(); // Prevents scroll when the input receives focus
      }}
      onChange={val => {
        if (val) {
          let parsedDate = moment(val).format("YYYY-MM-DD");
          setFieldValue(field.name, parsedDate);
        }
      }}
    />
), (prevProps, nextProps) => {
  return prevProps.field.value === nextProps.field.value && 
    prevProps.maxDate === nextProps.maxDate && 
    prevProps.minDate === nextProps.minDate;
});

const DatePickerField = ({ alwaysShow = false, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return <MemoDatePicker { ...{ field, setFieldValue, ...props }} />
};

export default DatePickerField;
