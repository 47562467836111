import React from "react";
import NumberOfApprovedRow from "./NumberOfApprovedRow";
import PercentageRow from "./PercentageRow";

const NumberOfApprovedTables = ({ title, data }) => {
  return (
    <div className="table-container mt-2">
      {title && <h2 className="text-center">{title}</h2>}
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col" className="text-center">Year</th>
            <th scope="col" className="text-center">January</th>
            <th scope="col" className="text-center">February</th>
            <th scope="col" className="text-center">March</th>
            <th scope="col" className="text-center">April</th>
            <th scope="col" className="text-center">May</th>
            <th scope="col" className="text-center">June</th>
            <th scope="col" className="text-center">July</th>
            <th scope="col" className="text-center">August</th>
            <th scope="col" className="text-center">September</th>
            <th scope="col" className="text-center">October</th>
            <th scope="col" className="text-center">November</th>
            <th scope="col" className="text-center">December</th>
            <th scope="col" className="text-center">Total</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((dat, key) => {
              return Object.keys(dat).map(year => (
                <NumberOfApprovedRow {...{ key, year }} data={dat[year]} />
              ));
            })}
          {data.length > 0 && <PercentageRow data={data}/>}
        </tbody>
      </table>
    </div>
  );
};

export default NumberOfApprovedTables;
