import React, { useState, useEffect } from "react";
import Chart from 'chart.js/auto';
import { Line } from "react-chartjs-2";
import Api from "../../../services/Api";


const SubmissionLineGraph = ({ status }) => {
  const [chartLabels, setChartLabels] = useState([]);
  const [submissionCounts, setSubmissionCounts] = useState([]);
  const [submissionsLoaded, setSubmissionsLoaded] = useState(false);

  DATE_FIELD_MAP = {
    null: 'created_at',
    undefined: "created_at",
    pending: "created_at",
    accepted: "status_changed_at",
    declined: "status_changed_at",
    complete: "status_changed_at",
  }

  CHART_TITLE_MAP = {
    null: 'Submissions Received (30 Days)',
    undefined: 'Submissions Received (30 Days)',
    pending: "Submissions Received (30 Days)",
    accepted: "Submissions Accepted (30 Days)",
    declined: "Submissions Declined (30 Days)",
    complete: "Submissions Completed (30 Days)",
  }

  const getSubmissionCounts = async () => {
    const { collection } = await Api.getData(
      `reports/submission_counts?status=${status || "all"}&date_field=${DATE_FIELD_MAP[status]}`
    );

    const submissionCounts = collection.map((item) => item.count);
    const chartLabels = collection.map((item) => item.date);
    setChartLabels(chartLabels);
    setSubmissionCounts(submissionCounts);
    setSubmissionsLoaded(true);
  }

  useEffect(() => {
    if (!submissionsLoaded) {
      getSubmissionCounts();
    }
  }, []);

  const suggestedMin = (Math.min(...submissionCounts) - 1 > 0) ? Math.min(...submissionCounts) - 1 : 0;
  const data = {
    labels: chartLabels,
    datasets: [{
      label: 'Submissions',
      data: submissionCounts,
      fill: false,
      borderColor: 'rgb(205,205,205)',
      backgroundColor: 'rgb(218,56,50)',
      showLine: true,
      tension: 0.1,
      pointRadius: 5,
    }],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: CHART_TITLE_MAP[status],
      },
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        ticks: {
          callback: function(value, index) {
            // Show label every 3 points
            return index % 3 === 0 ? this.getLabelForValue(value) : '';
          }
        }
      },
      y: {
        beginAtZero: true, // Start the y-axis at zero
        suggestedMin: suggestedMin, // Automatically fit to data
        suggestedMax: Math.max(...submissionCounts) + 1  // Automatically fit to data
      }
    }
  };
  return(
    <div className="chart-container">
      <Line
          type="scatter"
          data={data}
          options={options}
        />
    </div>
  );
};

export default SubmissionLineGraph;