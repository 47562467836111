import React, { useEffect, useState, useCallback } from "react";
import CustomForm from "../ui/CustomForm";
import ModalPage from "../ui/ModalPage";
import UpdateSuccessful from "../ui/UpdateSuccessful";
import submissionFormTemplate from "../../data/formTemplates/submissionFormTemplate";
import { submissionSchema } from "../../data/validationSchema";
import submission from "../../data/submission";
import Api from "../../services/Api";
import useModal from "../../global/useModal";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ReCaptchaWrapper from "./ReCaptchaWrapper";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const SubmissionForm = ({ environment, reCaptchaKey }) => {
  const [modalState, modalAction] = useModal();
  const [stores, setStores] = useState([]);
  const [reCaptchaToken, setReCaptchaToken] = useState(null);

  const useReCaptcha = environment === "production" ? true : false;

  useEffect(() => {
    return () => {
      modalAction.setModal(false);
    };
  }, []);

  useEffect(() => {
    if (!!submission["store_state"]) {
      getStores(submission["store_state"]);
    }
  }, []);

  const getStores = async (stateOption) => {
    try {
      setStores(await Api.getData(`cmtk/stores_by_state?state=${stateOption.value}`));
    } catch (error) {
      console.log("Error", error);
    }
  }

  const submitSubmissionForm = async (values) => {
    console.log(values['g-recaptcha-response'])
    if (useReCaptcha) { values["g-recaptcha-response"] ||= reCaptchaToken }
    console.log(values['g-recaptcha-response'])
    const { file, ...rest } = values;
    let tmpValues = await { org_documents: file, ...rest };
    let parsedValue = await parseSubmitValues(tmpValues);
    try {
      const result = await Api.postData("submissions", parsedValue, {
        "Content-Type": "multipart/form-data",
      });
      modalAction.setModal(true);
    } catch (error) {
      console.log(error);
      console.log(error.response.data.errors);
    }
  };

  const parseSubmitValues = (val) => {
    var data = new FormData();
    Object.keys(val).map((key) => {
      let type = typeof val[key];
      if (type == "object" && key != "org_documents") {
        data.append(key, val[key].value || val[key]);
      } else {
        if (key == "org_documents") {
          if (val[key] != undefined && val[key].length > 0) {
            val[key].forEach((file) => {
              data.append(`${key}[]`, file);
            });
          }
        } else {
          data.append(key, val[key]);
        }
      }
    });
    return data;
  };

  const customForm = <CustomForm
    initialValues={submission}
    validationSchema={submissionSchema}
    formTemplate={submissionFormTemplate(stores, getStores)}
    onSubmit={submitSubmissionForm}
    enableReinitialize={true}
    btnLabel="Submit"
    hasErrorFocus={true}
  />

  const renderForm = () => {
    if (useReCaptcha) {
      return (
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
          <ReCaptchaWrapper setReCaptchaToken={setReCaptchaToken}>
            {customForm}
          </ReCaptchaWrapper>
        </GoogleReCaptchaProvider>
      );
    }
    else {
      return customForm;
    }
  }

  return (
    <div className="my-3">
      <ModalPage>
        <UpdateSuccessful desc="Your submission was submitted!" cb={() => (location.reload())} />
      </ModalPage>
      {renderForm()}
    </div>
  );
};

export default SubmissionForm;
