import React from "react";
import ParsedDate from "../../../ui/ParsedDate";

const UserRow = ({ user, removeUser, updateUser }) => {
  return (
    <tr>
      <td className="pt-3 text-left">{user.name}</td>
      <td className="pt-3 text-left">{user.email}</td>
      <td className="pt-3 text-left">{user.assignable ? "Yes" : "No"}</td>
      <td className="d-flex flex-row justify-content-center">
        <button
          type="button"
          onClick={() => updateUser(user)}
          className="btn border-right btn-link"
        >
          Update
        </button>
        <button
          type="button"
          onClick={() => removeUser(user)}
          className="btn btn-link"
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default UserRow;
