import React from "react";

const PercentageRow = ({ data }) => {
  const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const calculatePercentage = (month, data) => {
    const currentYear = Object.keys(data[1])[0];
    const lastYear = Object.keys(data[0])[0];
    return ((data[1][currentYear].months[month] - data[0][lastYear].months[month]) / data[0][lastYear].months[month] * 100).toFixed(2);
  }

  const totalPercentage = (data) => {
    const currentYear = Object.keys(data[1])[0];
    const lastYear = Object.keys(data[0])[0];
    return ((data[1][currentYear].total - data[0][lastYear].total) / data[0][lastYear].total * 100).toFixed(2);
  }

  return (
    <tr>
      <td className="pt-3 text-center">Difference in %</td>
      {MONTHS.map((month) => (
        <td className="pt-3 text-center" key={month}>
          {calculatePercentage(month, data)}%
        </td>
      ))}
      <td className="pt-3 text-center">{totalPercentage(data)}</td>
      <td className="pt-3 text-center">{data.total}</td>
    </tr>
  );
};

export default PercentageRow;
