import React, { useState, useEffect } from "react";
import Chart from 'chart.js/auto';
import { Pie } from "react-chartjs-2";
import Api from "../../../services/Api";

const SubmissionPieChart = ({ status }) => {
  const [yearToDateTotal, setYearToDateTotal] = useState(0);
  const [yearToDateTotalLoaded, setYearToDateTotalLoaded] = useState(false);

  const getYearToDateTotal = async () => {
    const { total } = await Api.getData(
      `reports/year_to_date_total?status=${status || "all"}`
    );

    setYearToDateTotal(total);
    setYearToDateTotalLoaded(true);
  }

  useEffect(() => {
    if (!yearToDateTotalLoaded) {
      getYearToDateTotal();
    }
  }, []);

  return(
    <div>
      <div className="grey-chart-container">
        <div className="grey-circle">
          <span style={{color: '#DA3832', fontSize: '50px' }}><b>{yearToDateTotal}</b></span>
        </div>
      </div>
      <div className="grey-chart-container" style={{marginTop: '60px'}}>
        <h4 style={{color: '#999999'}}><b>YTD Total</b></h4>
      </div>
    </div>
  );
}

export default SubmissionPieChart;