import React, { useState, useEffect, useRef } from "react";

import CustomForm from "../../ui/CustomForm";
import LoadingView from "../../ui/LoadingView";
import SubmissionTable from "../tables/submissions/SubmissionTable";
import searchFormTemplate from "../../../data/formTemplates/submissionSearchFormTemplate";
import Api from "../../../services/Api";
import Utilities from "../../../services/Utilities";
import SubmissionStats from "./SubmissionStats";

const Submissions = ({ status }) => {
  const [pageInfo, setPageInfo] = useState({
    hasMoreLoading: false,
    page: 1,
    totalPages: null,
  });
  const [submissions, setSubmissions] = useState([]);
  const [totalSubmissions, setTotalSubmissions] = useState(0);
  const [users, setUsers] = useState([]);
  const [searchFields, setSearchFields] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [filters, setFilters] = useState({
    event_name: null,
    event_start_date: null,
    event_amount_request: null,
    status: null,
    updated_at: null,
    created_at: null,
  });

  const STATUS_MAP = {
    null: "All Submissions",
    undefined: "All Submissions",
    pending: "Pending Submissions",
    accepted: "Accepted Submissions",
    declined: "Declined Submissions",
    complete: "Completed Submissions",
  }

  ALL_SUBMISSIONS_SEARCH_FIELDS = {
    'keyword': true,
    'status': 'all',
    'store_number': true,
    'state': true,
    'start_date': true,
    'end_date': true,
    'cost': false,
  }

  PENDING_SUBMISSIONS_SEARCH_FIELDS = {
    'keyword': true,
    'status': false,
    'store_number': true,
    'state': true,
    'start_date': true,
    'end_date': true,
    'source': true,
    'cost': false,
  }

  ACCEPTED_SUBMISSIONS_SEARCH_FIELDS = {
    'keyword': true,
    'status': false,
    'store_number': true,
    'state': true,
    'start_date': true,
    'end_date': true,
    'source': true,
    'cost': WebTransportDatagramDuplexStream,
  }

  DECLINED_SUBMISSIONS_SEARCH_FIELDS = {
    'keyword': true,
    'status': 'declined',
    'store_number': true,
    'state': true,
    'start_date': true,
    'end_date': true,
    'source': true,
    'cost': false,
  }

  COMPLETE_SUBMISSIONS_SEARCH_FIELDS = {
    'keyword': true,
    'status': false,
    'store_number': true,
    'state': true,
    'start_date': true,
    'end_date': true,
    'source': true,
    'cost': true,
  }

  STATUS_SEARCH_FIELD_MAP = {
    null: ALL_SUBMISSIONS_SEARCH_FIELDS,
    undefined: ALL_SUBMISSIONS_SEARCH_FIELDS,
    pending: PENDING_SUBMISSIONS_SEARCH_FIELDS,
    accepted: ACCEPTED_SUBMISSIONS_SEARCH_FIELDS,
    declined: DECLINED_SUBMISSIONS_SEARCH_FIELDS,
    complete: COMPLETE_SUBMISSIONS_SEARCH_FIELDS,
  }

  useEffect(() => {
    getSubmissions();
  }, [pageInfo.page, filters, loading]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const data = await Api.getData("users?q[assignable_eq]=true")
      const users = data["collection"].map((user) => ({id: user.id, name: user.name}));
      setUsers(users);
    } catch (error) {
      console.log(error);
    }
  }

  const parseSubmitValues = (val) => {
    var data = {};
    Object.keys(val).map((key) => {
      let type = typeof val[key];
      if (!val[key]) return false;

      if (type == "object") {
        if (val[key][0]) {
          if (!data[key]) {
            data[key] = [];
          }
          val[key].forEach((v) => {data[key].push(v.value)});
        } else {
          data[key] = val[key].value
        }
      } else {
        data[key] = val[key];
      }
    });
    return data;
  };

  const getSubmissions = async () => {
    let parsedSearchParams = getParams();
    let encoded = `page=${pageInfo.page}&per_page=15&` + parsedSearchParams;
    try {
      const { collection, total_pages, current_page, total_count } = await Api.getData(
        `submissions?${encoded}`
      );
      setTotalSubmissions(total_count);
      collection.forEach((submission) => { submission.selected = false;});
      setSubmissions(collection);
      setPageInfo((prev) => ({ ...prev, totalPages: total_pages }));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setPageInfo((prev) => ({ ...prev, hasMoreLoading: false }));
      setError(true);
    }
  };

  const setSubmissionSelected = (submission) => {
    let newSubmissions = submissions.map((sub) => {
      if (sub.id == submission.id) {
        sub.selected = !sub.selected;
      }
      return sub;
    });

    setSubmissions(newSubmissions);
  }

  const getParams = () => {
    let params = {
      ...(searchFields.keyword && {
        org_name_or_event_name_cont: searchFields.keyword,
      }),
      ...(searchFields.start_date && {
        event_start_date_gteq: searchFields.start_date,
      }),
      ...(searchFields.end_date && {
        event_end_date_lteq: searchFields.end_date,
      }),
      ...((status && status != 'declined') && { status_eq: status }),
      ...((status && status == 'declined') && { status_in: ['declined', 'auto_declined'] }),
      ...((searchFields.source && searchFields.source.length > 0) && { source_in: searchFields.source }),
      ...((searchFields.status && searchFields.status.length > 0) && { status_in: searchFields.status }),
      ...((searchFields.costMin) && { event_amount_request_gteq: searchFields.costMin }),
      ...((searchFields.costMax) && { event_amount_request_lteq: searchFields.costMax }),
      ...(searchFields.store_number && {
        store_number_eq: searchFields.store_number,
      }),
      ...(searchFields.state && {
        store_state_eq: searchFields.state,
      }),
    };

    Object.keys(filters).map((filter) => {
      if (filters[filter]) {
        params["s"] = `${filter} ${filters[filter]}`;
      }
    });

    return Utilities.serializeRansackParams(params);
  };

  const exportCSV = () => {
    let params = getParams();
    return `/api/v1/submissions.xlsx?per_page=999999&${params}`;
  };

  const setAssignedTo = async (submission, user) => {
    let choice = await Utilities.alertConfirm(
      "Confirm Assign",
      `Are you sure you want to assign ${submission.event_name} to ${user.name}?`,
      "Yes",
      "No"
    );
    if (choice) {
      try {
        const result = await Api.postData(
          `submissions/${submission.id}/assign_user`,
          { assigned_to_id: user.value }
        );
        searchSubmissions();
      } catch (error) {
        console.log(error);
      }
    }
  }

  const removeSubmission = async (submission) => {
    let choice = await Utilities.alertConfirm(
      "Confirm Delete",
      `Are you sure you want to remove ${submission.event_name} submission?`,
      "Yes",
      "No"
    );
    if (choice) {
      try {
        const result = await Api.deleteData(`submissions/${submission.id}`);
        searchSubmissions();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const duplicateSubmission = async (submission) => {
    let choice = await Utilities.alertConfirm(
      "Confirm Duplication",
      `Are you sure you want to duplicate ${submission.event_name} submission?`,
      "Yes",
      "No"
    );
    if (choice) {
      try {
        const result = await Api.duplicateData(`submissions/${submission.id}/duplicate`);
        searchSubmissions();
      } catch (error) {
        console.log(error);
      }
    }
  }

  const declineSubmission = async (submission) => {
    let choice = await Utilities.alertConfirm(
      "Confirm Decline",
      `Are you sure you want to Decline ${submission.event_name} submission?`,
      "Yes",
      "No"
    );
    if (choice) {
      try {
        const result = await Api.duplicateData(`submissions/${submission.id}/decline`);
        searchSubmissions();
      } catch (error) {
        console.log(error);
      }
    }
  }

  const bulkDecline = async () => {
    let selectedSubmissions = submissions.filter(sub => sub.selected);
    if (selectedSubmissions.length == 0) {
      Utilities.alertError("No submissions selected", "Please select one or more submissions to decline.");
      return;
    }

    let choice = await Utilities.alertConfirm(
      "Confirm Bulk Decline",
      `Are you sure you want to Decline ${selectedSubmissions.length} submissions?`,
      "Yes",
      "No"
    );
    if (choice) {
      try {
        let ids = selectedSubmissions.map(sub => sub.id);
        await Api.postData(`submissions/bulk_decline`, { submission_ids: ids });
        searchSubmissions();
      } catch (error) {
        console.log(error);
      }
    }
  }

  const searchSubmissions = async (values = {}) => {
    setLoading(true);
    let parsedValues = {};

    if (values) {
      parsedValues = await parseSubmitValues(values);
      setSearchFields(parsedValues);
    }
    resetSubmissionData();
  };

  const applyFilter = (type) => {
    let newType;
    if (!filters[type] || filters[type] == "DESC") {
      newType = "ASC";
    } else {
      newType = "DESC";
    }
    let newFilters = {
      [type]: newType,
    };
    Object.keys(filters).map((filter) => {
      if (filter != type) {
        newFilters[filter] = null;
      }
    });
    setFilters({ ...newFilters });
    resetSubmissionData();
  };

  const resetSubmissionData = () => {
    setSubmissions([]);
    setPageInfo({
      page: 1,
      totalPages: null,
    });
  };

  return (
    <div className="container-fluid mt-2">
      <SubmissionStats status={status} />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3">
          <div className="card">
            <div className="card-body">
              <h2>Search</h2>
              <p>
                Enter a keyword below to search across most fields attached to
                sponsorship records.
              </p>
              <CustomForm
                initialValues={{
                  keyword: "",
                  start_date: null,
                  end_date: null,
                  source: null,
                  status: null,
                  store_number: null,
                  state: null,
                }}
                fixedPlaceholder={true}
                enableReinitialize={true}
                formTemplate={searchFormTemplate(STATUS_SEARCH_FIELD_MAP[status])}
                onSubmit={searchSubmissions}
                btnClass="btn-block"
                btnLabel="Search"
              />
              <a
                className="btn btn-dark w-100 mt-4 p-2"
                href={exportCSV()}
                target="_blank"
              >
                Export CSV
              </a>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-9">
          <div className="card">
            <div className="card-body submissionContainer">
              <h1 className="mb-4">{STATUS_MAP[status]}</h1>
              <LoadingView {...{ loading, error }}>
                <SubmissionTable
                  {...{
                    submissions,
                    pageInfo,
                    setPageInfo,
                    getSubmissions,
                    removeSubmission,
                    duplicateSubmission,
                    declineSubmission,
                    setSubmissionSelected,
                    bulkDecline,
                    users,
                    filters,
                    setAssignedTo,
                    applyFilter,
                    status
                  }}
                />
              </LoadingView>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Submissions;
