import axios from "axios";

export default class {
  static API_URL = "/api/v1/";

  // Get Submission
  static getData(append, header = { "Accept": "application/json" }) {
    return new Promise((resolve, reject) => {
      axios
        .get(this.API_URL + append, { header })
        .then(response => {
          resolve(response.data);
        }) 
        .catch(response => {
          reject(response);
        });
    });
    
  }

  // Post Submission
  static postData(
    append,
    params = {},
    header = { "Content-Type": "application/json", "Accept": "application/json" }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(this.API_URL + append, params, {
          header
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(response => {
          reject(response);
        });
    });
  }

  // Patch
  static patchData(
    append,
    params = {},
    header = { "Content-Type": "application/json", "Accept": "application/json" }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .patch(this.API_URL + append, params, { headers: header })
        .then(response => {
          resolve(response.data);
        })
        .catch(response => {
          reject(response);
        });
    });
  }

  // Delete
  static deleteData(append) {
    return new Promise((resolve, reject) => {
      axios
        .delete(this.API_URL + append)
        .then(response => {
          resolve(response.data);
        })
        .catch(response => {
          reject(response);
        });
    });
  }

  // Duplicate
  static duplicateData(append){
    return new Promise((resolve, reject) => {
      axios
        .post(this.API_URL + append)
        .then(response => {
          resolve(response.data);
        })
        .catch(response => {
          reject(response);
        });
    });
  }
}
