import React, { useCallback, useEffect } from "react";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ReCaptchaWrapper = ({ setReCaptchaToken, children }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    console.log("Executing ReCaptcha");
    const token = await executeRecaptcha('Submission');
    setReCaptchaToken(token);
    
  }, [executeRecaptcha]);

  const verifyRecaptcha = async () => {
    const token = await executeRecaptcha('Submission');
    setReCaptchaToken(token);
    return token;
  }

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);
  
  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        verifyRecaptcha: verifyRecaptcha,
      });
    });
  }
  return <>{renderChildren()}</>
}

export default ReCaptchaWrapper;