import React from "react";

const GoBack = ({ label = "< Go Back", cb = () => {} }) => {
  return (
      <button type="button" className="btn btn-dark mb-2" onClick={cb}>
        {label}
      </button>
  );
};

export default GoBack;
