import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { geoAlbersUsa } from 'd3-geo';
import Tippy, { useSingleton } from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Import Tippy CSS
import SubmissionMapLegend from './SubmissionMapLegend';

const getColor = (value) => {
  if (value === 0) return "#ffeaea"; // 0
  if (value >= 1 && value <= 249) return "#fbbebf"; // 1-249
  if (value >= 250 && value <= 499) return "#f09290"; // 250-499
  if (value >= 500 && value <= 749) return "#e56661"; // 500-749
  if (value >= 750 && value <= 999) return "#da3832"; // 750-999
  if (value >= 1000) return "#A02817"; // 1000+
  return "#D6D6DA"; // Default color
};

const SubmissionMap = ({ states, stateAbbreviations }) => {
  const [tooltipContent, setTooltipContent] = useState("");
  const [hoveredState, setHoveredState] = useState(null);
  const [source, target] = useSingleton();
  const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

  const handleMouseEnter = (geo) => {
    const { name } = geo.properties;
    const abbreviation = stateAbbreviations[name];
    const value = states[abbreviation] || 0;
    setHoveredState(name);
    setTooltipContent(`${name} - Submissions: ${value}`);
  };

  const handleMouseLeave = () => {
    setHoveredState(null);
    setTooltipContent("");
  };

  return (
    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
        <SubmissionMapLegend />
      </div>
      <ComposableMap projection={geoAlbersUsa()} viewBox="0 0 900 600">
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map(geo => (
              <Tippy
                singleton={target}
                content={hoveredState === geo.properties.name && (
                  <span style={{ color: 'black', backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
                    {tooltipContent}
                  </span>
                )}
                key={geo.rsmKey}
                theme="light"
              >
                <Geography
                  geography={geo}
                  onMouseEnter={() => handleMouseEnter(geo)}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    default: {
                      fill: getColor(states[stateAbbreviations[geo.properties.name]] || 0),
                      outline: "none",
                      stroke: "#FFFFFF", 
                      strokeWidth: 0.125 
                    },
                    hover: {
                      fill: "#000000",
                      outline: "none"
                    },
                    pressed: {
                      fill: "#E42",
                      outline: "none"
                    }
                  }}
                />
              </Tippy>
            ))
          }
        </Geographies>
      </ComposableMap>
      <Tippy singleton={source} />
    </div>
  );
};

export default SubmissionMap;