import responsive from "../../themes/Responsive";
import { states } from "../selections";

const acceptedFormTemplate = (users) => ({
  eventInformation: {
    label: "Event Information",
    fields: [
      {
        name: "store_state",
        type: "select",
        placeholder: "State*",
        responsive: responsive.thirdFormWidth,
        options: states,
      },
      {
        type: "text",
        name: "store_number",
        placeholder: "Store Number",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "event_name",
        type: "text",
        placeholder: "Event Name",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "sponsorship_type",
        type: "select",
        placeholder: "Sponsorship Type",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: "community_event", label: "Community Event" },
          { value: "disaster_relief", label: "Disaster Relief" },
          { value: "equine", label: "Equine" },
          { value: "fair", label: "Fair" },
          { value: "first_responder_support", label: "First Responder Support" },
          { value: "livestock", label: "Livestock" },
          { value: "other", label: "Other" },
          { value: "pet", label: "Pet" },
          { value: "rodeo_sponsorship_type", label: "Rodeo Sponsorship Type" },
          { value: "youth_education", label: "Youth Education" },
          { value: "4h_ffa", label: "4H/FFA" },
        ]
      },
      {
        name: "donation_type",
        type: "select",
        placeholder: "Donation Type",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: "monetary", label: "Monetary" },
          { value: "product", label: "Product" },
          { value: 'gift_card', label: 'Gift Card' },
        ]
      },
      {
        name: "product_donation_description",
        type: "text",
        placeholder: "Product Donation Description",
        responsive: responsive.thirdFormWidth,
        display: (formState) => (formState.donation_type?.value === "product")
      },
      {
        name: "event_amount_request",
        type: "currency",
        placeholder: (formState) => (formState.donation_type?.value == "product" ? "Product Monetary Value" : "Monetary Amount Requesting*"),
        responsive: responsive.thirdFormWidth,
      },
      {
        name: 'event_description_of_opportunity',
        component: 'textarea',
        row: 5,
        placeholder: 'Other Event Details',
        responsive: responsive.fullFormWidth,
      },
      {
        name: "event_description_of_request",
        component: "textarea",
        row: 5,
        // TODO: CHANGE PLACEHOLDER TEXT
        placeholder: "Overview of Event/Request*",
        responsive: responsive.fullFormWidth,
      },
      {
        name: "sponsored_before",
        type: "select",
        placeholder: "TSC Previously Sponsored*",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ],
      },
      {
        name: "status",
        type: "select",
        placeholder: "Status",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: "pending", label: "Pending" },
          { value: "accepted", label: "Accepted" },
          { value: "declined", label: "Declined" },
          { value: "auto_declined", label: "Auto Declined" },
        ]
      }, {
        name: "assigned_to_id",
        type: "select",
        placeholder: "Assigned To",
        responsive: responsive.thirdFormWidth,
        options: users
      },
      {
        name: 'account_codes',
        type: 'select',
        isMulti: true,
        placeholder: 'Account Codes',
        responsive: responsive.thirdFormWidth,
        options: [
          { value: '81260', label: '81260 - Store Marketing', color: 'red' },
          { value: '82100', label: '82100 - Community Partnership', color: 'blue', },
          { value: '82111', label: '82111 - Free Events', color: 'green' },
          { value: '82122', label: '82122 - Competitive', color: 'purple'},
          { value: '82150', label: '82150 - Gift Card Events', color: 'orange'},
          { value: '82600', label: '82600 - New Stores', color: 'black'},
          { value: '88888', label: '88888 - State Fairs', color: 'grey'},
        ]
      },
      {
        name: "comment",
        type: "text",
        placeholder: "Comment",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "vendor_number",
        type: "text",
        placeholder: "Vendor Number",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "notes",
        component: "textarea",
        placeholder: "Sponsorship Team Notes",
        responsive: responsive.fullFormWidth,
        row: 5
      }, {
        name: "event_start_date",
        type: "date",
        maxDateIdentifier: "event_end_date",
        placeholder: "Event Start Date",
        responsive: responsive.thirdFormWidth,
        inline: true
      }, {
        name: "event_end_date",
        type: "date",
        minDateIdentifier: "event_start_date",
        placeholder: "Event End Date",
        responsive: responsive.thirdFormWidth,
        inline: true
      }, {
        name: "event_budget_date",
        type: "date",
        placeholder: "Event Budget Date",
        responsive: responsive.thirdFormWidth,
        dateFormat: "MM/yyyy",
        showMonthYearPicker: true,
        inline: true
      },
    ],
  },
  contactInformation: {
    label: "Contact Information",
    fields: [
      {
        name: "submission_type",
        type: "select",
        placeholder: "Type of Request*",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: "charity_and_fundraising", label: "Charity and Fundraising" },
          { value: "general_sponsorship", label: "General Sponsorship" },
          { value: "partnership_submission", label: "Partnership Request" },
          { value: "product_donation", label: "Product Donation" },
          { value: 'rodeo', label: 'Rodeo'},
          { value: "advertising", label: "Advertising" },
        ],
      },
      {
        name: "org_name",
        type: "text",
        placeholder: "Organization Name*",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "contact_first_name",
        type: "text",
        placeholder: "Contact First Name*",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "contact_last_name",
        type: "text",
        placeholder: "Contact Last Name*",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "contact_email",
        type: "text",
        placeholder: "Contact Email Address*",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "contact_phone_number",
        type: "tel",
        placeholder: "Contact Phone Number*",
        responsive: responsive.thirdFormWidth,
      },
    ],
  },
  orgDocuments: {
    label: "",
    fields: [
      {
        name: "org_documents",
        type: "file",
        hasFile: true,
        multiple: true,
        responsive: responsive.fullFormWidth,
      },
    ],
  },
});

export default acceptedFormTemplate;
