import responsive from "../../themes/Responsive";
import { states } from "../selections";

const submissionFormTemplate = (users) => ({
  eventInformation: {
    label: "Event Information",
    fields: [
      {
        name: "store_state",
        type: "select",
        placeholder: "State of TSC Store Closest To Event*",
        responsive: responsive.thirdFormWidth,
        options: states,
      },
      {
        type: "text",
        name: "store_number",
        placeholder: "Store Number",
        responsive: responsive.thirdFormWidth,
      },
      { 
        name: "location_partner",
        type: "select",
        placeholder: "TSC Location Partner",
        responsive: responsive.fullFormWidth,
        options: [
          { value: true, label: "Yes" },
          { value: false, label: "No" }
        ]
      },
      {
        name: "event_name",
        type: "text",
        placeholder: "Name of Opportunity/Iniative/Event",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "event_type",
        type: "select",
        placeholder: "Type of Event",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: "auction", label: "Auction" },
          { value: "awards_banquet", label: "Awards Banquet" },
          { value: "benefit_concert", label: "Benefit Concert" },
          { value: "event_sponsorship", label: "Event Sponsorship" },
          { value: "gala_celebration", label: "Gala Celebration" },
          { value: "individual_sponsorship", label: "Individual Sponsorship" },
          { value: "sports_outing", label: "Sports Outing e.g. golf tournament" },
          { value: "telethon", label: "Telethon" },
          { value: "walkathon", label: "Walkathon" },
          { value: "10k_race", label: "10K Race" },
        ]
      },
      {
        name: "sponsorship_type",
        type: "select",
        placeholder: "Sponsorship Type",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: "community_event", label: "Community Event" },
          { value: "disaster_relief", label: "Disaster Relief" },
          { value: "equine", label: "Equine" },
          { value: "fair", label: "Fair" },
          { value: "first_responder_support", label: "First Responder Support" },
          { value: "livestock", label: "Livestock" },
          { value: "other", label: "Other" },
          { value: "pet", label: "Pet" },
          { value: "rodeo_sponsorship_type", label: "Rodeo Sponsorship Type" },
          { value: "youth_education", label: "Youth Education" },
          { value: "4h_ffa", label: "4H/FFA" },
        ]
      },
      {
        name: "org_legal_name",
        type: "text",
        placeholder: "Organization Legal Name",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "event_attendance",
        type: "select",
        placeholder: "Expected/Historical Historical Event Attendance",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: "attendance_none", label: "No on-site audience" },
          { value: "0_100", label: "0-100" },
          { value: "101_250", label: "101-250" },
          { value: "251_500", label: "251-500" },
          { value: "501_1000", label: "501-1000" },
          { value: "1001_5000", label: "1001-5000" },
          { value: "5001_10000", label: "5001-10000" },
          { value: "10001_25000", label: "10001-25000" },
          { value: "25001_75000", label: "25001-75000" },
          { value: "75001_250000", label: "75001-250000" },
          { value: "250001+", label: "250001+" },
          
        ]
      },
      {
        name: "donation_type",
        type: "select",
        placeholder: "Donation Type",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: "monetary", label: "Monetary" },
          { value: "product", label: "Product" },
          { value: 'gift_card', label: 'Gift Card' },
        ]
      },
      {
        name: "product_donation_description",
        type: "text",
        placeholder: "Product Donation Description",
        responsive: responsive.thirdFormWidth,
        display: (formState) => (formState.donation_type?.value === "product")
      },
      {
        name: "event_amount_request",
        type: "currency",
        placeholder: (formState) => (formState.donation_type?.value == "product" ? "Product Monetary Value" : "Monetary Amount Requesting*"),
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "funds_purpose",
        type: "select",
        placeholder: "Primary use of Funds?*",
        responsive: responsive.thirdFormWidth,
        display: (formState) => (formState.donation_type?.value === "monetary"),
        options: [
          { value: "applied_to_operational_costs", label: "Applied to Operational Costs" },
          { value: "building_or_renovation_project", label: "Building or Renovation Project" },
          { value: "endowment_fund", label: "Endowment Fund" },
          { value: "education", label: "Education" },
          { value: "food_or_shelter_assistance", label: "Food or Shelter Assistance" },
          { value: "research_project", label: "Research Project" },
          { value: "counseling_and_outreach_programs", label: "Counseling and Outreach Programs" },
          { value: "scholarship_program", label: "Scholarship Program, Stipends or Fellowships" },
          { value: "recreational_activities", label: "Recreational Activities (Sports Programs or Competition)" },
        ]
      },
      {
        name: "event_description_of_request",
        component: "textarea",
        row: 5,
        // TODO: CHANGE PLACEHOLDER TEXT
        placeholder: "Event Description",
        responsive: responsive.fullFormWidth,
      },
      {
        name: "sponsored_before",
        type: "select",
        placeholder: "Tsc Previously Sponsored",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ],
      },
      {
        name: "can_pickup",
        type: "select",
        placeholder: "Can Pickup",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]
      },
      {
        name: "print_add_due",
        type: "date",
        maxDateIdentifier: "print_add_due",
        placeholder: "Print Ad Due Date",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "event_start_date",
        type: "date",
        maxDateIdentifier: "event_end_date",
        placeholder: "Event Start Date",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "event_end_date",
        type: "date",
        minDateIdentifier: "event_start_date",
        placeholder: "Event End Date",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "commitment_deadline",
        type: "date",
        placeholder: "Commitment Deadline",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "status",
        type: "select",
        placeholder: "Status",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: "pending", label: "Pending" },
          { value: "accepted", label: "Accepted" },
          { value: "declined", label: "Declined" },
          { value: "auto_declined", label: "Auto Declined" },
        ]
      }, {
        name: "source",
        type: "select",
        placeholder: "Source",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: "cmtk", label: "CMTK"},
          { value: "public_submission", label: "Public" },
        ]
      }, {
        name: "assigned_to_id",
        type: "select",
        placeholder: "Assigned To",
        responsive: responsive.thirdFormWidth,
        options: users
      },
      {
        name: "age_verify",
        type: "checkbox",
        placeholder: "18+",
        text: "18+",
        responsive: responsive.fullFormWidth
      },
    ],
  },
  contactInformation: {
    label: "Contact Information",
    fields: [
      {
        name: "submission_type",
        type: "select",
        placeholder: "Type of Request*",
        responsive: responsive.thirdFormWidth,
        options: [
          { value: "charity_and_fundraising", label: "Charity and Fundraising" },
          { value: "general_sponsorship", label: "General Sponsorship" },
          { value: "partnership_submission", label: "Partnership Request" },
          { value: "product_donation", label: "Product Donation" },
          { value: 'rodeo', label: 'Rodeo'},
          { value: "advertising", label: "Advertising" },
        ],
      },
      {
        name: "org_name",
        type: "text",
        placeholder: "Organization Name*",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "contact_first_name",
        type: "text",
        placeholder: "Contact First Name*",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "contact_last_name",
        type: "text",
        placeholder: "Contact Last Name*",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "contact_email",
        type: "text",
        placeholder: "Contact Email Address*",
        responsive: responsive.thirdFormWidth,
      },
      {
        name: "contact_phone_number",
        type: "tel",
        placeholder: "Contact Phone Number*",
        responsive: responsive.thirdFormWidth,
      },
    ],
  },
  // organizationInformation: {
  //   label: "Organization Information",
  //   fields: [
  //     {
  //       name: "non_profit",
  //       type: "select",
  //       placeholder: "Organization a Government Sanctioned Non-Profit?*",
  //       responsive: responsive.fullFormWidth,
  //       options: [
  //         { value: true, label: "Yes" },
  //         { value: false, label: "No" },
  //       ]
  //     },
  //     {
  //       name: "org_primary_focus",
  //       type: "select",
  //       placeholder: "Primary Focus of Organization*",
  //       responsive: responsive.thirdFormWidth,

  //       options: [
  //         { value: "animal_protection", label: "Animal Protection, Welfare & Services" },
  //         { value: "arts_and_humanities", label: "Arts & Humanities" },
  //         { value: "children_and_family", label: "Children & Family" },
  //         { value: "civil_rights_and_liberties", label: "Civil Rights & Liberties" },
  //         { value: "cultural_and_ethnic", label: "Cultural & Ethnic" },
  //         { value: "disabled", label: "Disabled" },
  //         { value: "education_and_schools", label: "Education and Schools" },
  //         { value: "employment_mentoring", label: "Employment, Mentoring Programs" },
  //         { value: "environment_and_conservation", label: "Environment & Conservation" },
  //         { value: "general_human_services", label: "General Human Services" },
  //         { value: "health_and_wellness", label: "Health & Wellness" },
  //         { value: "international_human_rights", label: "International Human Rights" },
  //         { value: "libraries", label: "Libraries" },
  //         { value: "medical_research", label: "Medical Research" },
  //         { value: "mental_health_and_crisis", label: "Mental Health & Crisis Services" },
  //         { value: "performing_arts", label: "Performing Arts" },
  //         { value: "political", label: "Political" },
  //         { value: "poverty_and_homelessness", label: "Poverty & Homelessness" },
  //         { value: "public_broadcasting", label: "Public Broadcasting" },
  //         { value: "public_safety", label: "Public Safety, Disaster Preparedness & Relief" },
  //         { value: "religious", label: "Religious" },
  //         { value: "renovation_and_preservation", label: "Renovation, Preservation" },
  //         { value: "research_institutes", label: "Research Institutes" },
  //         { value: "seniors", label: "Seniors" },
  //         { value: "shelter_and_housing", label: "Shelter, Housing" },
  //         { value: "veterans_and_military", label: "Veterans & Military" },
  //         { value: "voter_education", label: "Voter Education & Registration" },
  //         { value: "wildlife_conservation", label: "Wildlife Conservation" },
  //         { value: "women", label: "Women"},
  //         { value: "youth_clubs_recreation_sports", label: "Youth Clubs, Recreation & Sports" },
  //         { value: "youth_development", label: "Youth Development" }
  //       ],
  //     },
  //     {
  //       name: 'national',
  //       type: "select",
  //       placeholder: "Local or National?",
  //       responsive: responsive.thirdFormWidth,
  //       options: [
  //         { value: true, label: "National" },
  //         { value: false, label: "Local" }
  //       ]
  //     },
  //     {
  //       name: 'members',
  //       type: "number",
  //       placeholder: "How many members nationwide?",
  //       responsive: responsive.thirdFormWidth,
  //     },
  //     {
  //       name: "non_profit_tax_id_number",
  //       type: "text",
  //       placeholder: "Enter Non-profit Tax ID Number*",
  //       responsive: responsive.thirdFormWidth,
  //     },
  //     {
  //       name: "org_status",
  //       type: "select",
  //       placeholder: "(IRS): Non-profit Organization Status*",
  //       responsive: responsive.thirdFormWidth,
  //       options: [
  //         { value: "501c1", label: "501(c)(1)" },
  //         { value: "501c2", label: "501(c)(2)" },
  //         { value: "501c3", label: "501(c)(2)" },
  //         { value: "501d", label: "501(d)" },
  //         { value: "501e", label: "501(e)" },
  //         { value: "501f", label: "501(f)" },
  //         { value: "501k", label: "501(k)" },
  //         { value: "521a", label: "521(a)" },
  //         { value: "527", label: "527" },
  //         { value: "IT496R", label: "IT-496R" },
  //         { value: "not_applicable", label: "Not Applicable" },
  //       ],
  //     },
  //     {
  //       name: "org_legal_name",
  //       type: "text",
  //       placeholder: "(IRS): Organization Legal Name*",
  //       responsive: responsive.thirdFormWidth,
  //     },
  //     {
  //       name: "org_address",
  //       type: "text",
  //       placeholder: "(IRS): Organization Address*",
  //       responsive: responsive.thirdFormWidth,
  //     },
  //     {
  //       name: "org_city",
  //       type: "text",
  //       placeholder: "(IRS): Organization City*",
  //       responsive: responsive.thirdFormWidth,
  //     },
  //     {
  //       name: "org_state",
  //       type: "select",
  //       placeholder: "(IRS): Organization State*",
  //       responsive: responsive.thirdFormWidth,
  //       options: states,
  //     },
  //     {
  //       name: "org_zip_code",
  //       type: "num",
  //       placeholder: "(IRS): Organization Zip Code*",
  //       responsive: responsive.thirdFormWidth,
  //     },
  //   ],
  // },
  orgDocuments: {
    label: "",
    fields: [
      {
        name: "org_documents",
        type: "file",
        hasFile: true,
        multiple: true,
        responsive: responsive.fullFormWidth
      }
    ]
  }
});

export default submissionFormTemplate;
