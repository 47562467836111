// app/javascript/components/Legend.jsx
import React from 'react';

const SubmissionMapLegend = () => {
  const legendItems = [
    { color: "#A02817", label: "1000+" },
    { color: "#da3832", label: "750-999" },
    { color: "#e56661", label: "500-749" },
    { color: "#f09290", label: "250-499" },
    { color: "#fbbebf", label: "1-249" },
    { color: "#ffeaea", label: "0" }
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', padding: '10px', borderRadius: '5px' }}>
      {legendItems.map(item => (
        <div key={item.label} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
          <span style={{ marginRight: '3px', color: '#99999C'  }}>{item.label}</span>
          <div style={{ width: '20px', height: '20px', backgroundColor: item.color, marginRight: '10px' }}></div>
        </div>
      ))}
    </div>
  );
};

export default SubmissionMapLegend;