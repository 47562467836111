import React from "react";
import Utilities from '../../../../services/Utilities'

const BudgetMainRow = ({
  title,
  data,
  type,
  totalType,
  onClick = () => {},
}) => {
  const displayData = (data) => {
    let result = "0.00";
    if (data) {
      if (type == "budget") {
        result = `${data.amount}`;
      } else {  
          result = `${data}`;
      }
    }
    return Utilities.formatCurrency(result) ;
  };

  if (!data) return null;
  return (
    <tr>
      <td className="text-right" style={{ minWidth: "5rem" }}>
        {title}
      </td>
      {data.months &&
        Object.keys(data.months).map((month) => (
          <td
            style={{ minWidth: "5rem" }}
            key={month}
            onClick={() => onClick(month)}
          >
            {displayData(data.months[month][type])}
          </td>
        ))}
      <td className="text-right" style={{ minWidth: "5rem" }}>
        {data.total[totalType] ? Utilities.formatCurrency(data.total[totalType]) : "$0.00"}
      </td>
    </tr>
  );
};

export default BudgetMainRow;
