import React from 'react';
import { buildPlaceHolder } from './Helpers';
import MaskedInputField from '../MaskedInputField';
import FieldErrorMessage from '../FieldErrorMessage';

const CustomFormPhoneNumber = ({
  responsive,
  fixedPlaceholder,
  placeholder,
  name,
  type,
  formik: {
    values,
    errors,
    touched
  },
  callbacks,
  ...props
}) => (
  <div className={`form-group ${responsive}`}>
    {fixedPlaceholder && <label>{buildPlaceHolder(placeholder, values)}</label>}
    <MaskedInputField
      {...props}
      name={name}
      type={type}
      placeholder={buildPlaceHolder(placeholder, values)}
      fixedPlaceholder={fixedPlaceholder}
      className={`form-control py-3 ${
        errors[name] && touched[name]
          ? "is-invalid"
          : ""
      }`}
    />
    <FieldErrorMessage
      error={errors[name]}
      touched={touched[name]}
    />
  </div>
);

export default CustomFormPhoneNumber;