import React from "react";

const SubmissionsByAmountTable = ({ title, data }) => {
  return (
    <div className="table-container mt-2">
      {title && <h2 className="text-center">{title}</h2>}
      <table className="table six-columns">
        <thead className="thead-light">
          <tr >
            <th scope="col"className="text-center"></th>
            <th scope="col"className="text-center">Free</th>
            <th scope="col"className="text-center">Under $500</th>
            <th scope="col"className="text-center">$500-$750</th>
            <th scope="col"className="text-center">$751-$950</th>
            <th scope="col"className="text-center">$951-$1200</th>
            <th scope="col"className="text-center">$1201+</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" className="text-center">Number of Requests</td>
            <td scope="row" className="text-center">{data.free}</td>
            <td scope="row" className="text-center">{data.under_500}</td>
            <td scope="row" className="text-center">{data.between_500_750}</td>
            <td scope="row" className="text-center">{data.between_751_950}</td>
            <td scope="row" className="text-center">{data.between_951_1200}</td>
            <td scope="row" className="text-center">{data.over_1200}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SubmissionsByAmountTable;