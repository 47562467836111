import React, { useState } from "react";
import ParsedDate from "../../../ui/ParsedDate";
import Select from "react-select";
import Api from "../../../../services/Api";
import { Tooltip } from 'react-tooltip'

const SubmissionRow = ({ submission, removeSubmission, duplicateSubmission, declineSubmission, setSubmissionSelected, users, setAssignedTo, tableColumns }) => {
  const [comment, setComment] = useState(submission.comment);

  const sourceMap = {
    public_submission: "Public",
    cmtk: "CMTK",
  };

  const updateComment = (e) => {
    setComment(e.target.value);
  }

  const userOptions = users.map((user) => (
    { value: user.id, label: user.name }
  ));

  STATUS_MAP = {
    "pending": "Pending",
    "accepted": "Accepted",
    "declined": "Declined",
    "auto_declined": "Auto Declined",
  }

  const commentBlur = async (e) => {
    const comment = e.target.value;
    try {
      await Api.patchData(`submissions/${submission.id}/update_comment`, { comment });
    } catch (error) {
      console.log(error);
    } 
  }

  return (
    <tr className={submission.selected ? 'highlight' : ''}>
      {tableColumns['decline'] && <td className="pt-3"><input type={"checkbox"} onChange={() => {setSubmissionSelected(submission)}}></input></td>}
      {tableColumns['event_name'] && <td className="pt-2 text-capitalize text-left">
        {submission.source == 'cmtk' && <img
          id="tsclogo"
          src={"/tsc_logo_icon.png"}
          className="tscMiniLogo"
        />}
        <a
          type="button"
          className="pt-1"
          href={`/admin/submissions/${submission.id}/edit`}
        >
          {submission.event_name}
        </a>
        <br/>
        {tableColumns['comment'] && <input name="comment" style={{width: '100%'}} onChange={updateComment} onBlur={commentBlur} value={comment} className="mt-1" placeholder="Enter a comment"/>}
      </td>}
      {tableColumns['store_number'] && <td className="pt-3">{submission.store_number}</td>}
      {tableColumns['zone'] && <td className="pt-3">{submission.zone}</td>}
      {tableColumns['event_amount_request'] && <td className="pt-3">${submission.event_amount_request}</td>}
      {tableColumns['assigned_to_id'] && <td className="pt-3">
        <Select
          name="assigned_to_id"
          value={userOptions
            ? userOptions.find(option => option.value === submission.assigned_to_id)
            : ""}
          placeholder=""
          onChange={(val) => {setAssignedTo(submission, val)}}
          options={userOptions}
        />
      </td>}
      {tableColumns['status'] && <td className="pt-3">
        <span>{STATUS_MAP[submission.status]}</span>
      </td>}
      {tableColumns['created_at'] && <td className="pt-3">
        <ParsedDate date={submission.created_at} parse="YYYY-MM-DD" />
      </td>}
      {tableColumns['updated_at'] && <td className="pt-3">
        <ParsedDate date={submission.updated_at} parse="YYYY-MM-DD" />
      </td>}
      {tableColumns['last_viewed_at'] && <td className="pt-3">
        { !!submission.last_viewed_at ? <ParsedDate date={submission.last_viewed_at} parse="YYYY-MM-DD" /> : 'Never'}
      </td>}
      <td className="d-flex flex-row justify-content-center">
        <a
          href={`/admin/submissions/${submission.id}/edit`}
          className="btn btn-link"
          style = {{padding: '1px 4px 1px 4px'}}
          data-tooltip-id={`edit-tooltip${submission.id}`}
        >
          <img
            id="headerImg"
            src={"/pen-blue.svg"}
            style = {{width: '20px'}}
          />
          <Tooltip id={`edit-tooltip${submission.id}`} >Edit</Tooltip>
        </a>
        {tableColumns['decline'] && <button
          onClick={() => declineSubmission(submission)}
          className="btn btn-link"
          style = {{padding: '1px 4px 1px 4px'}}
          data-tooltip-id={`decline-tooltip${submission.id}`}
        >
          <img
            id="headerImg"
            src={"/delete-outline.svg"}
            style = {{width: '20px'}}
          />
          <Tooltip id={`decline-tooltip${submission.id}`} >Decline</Tooltip>
        </button>}
        <button
          data-tooltip-id={`duplicate-tooltip${submission.id}`}
          onClick={() => duplicateSubmission(submission)}
          className="btn btn-link border-right"
          style = {{padding: '1px 8px 1px 4px'}}
        >
          <img
            id="headerImg"
            src={"/duplicate.svg"}
            style = {{width: '20px'}}
          />
          <Tooltip id={`duplicate-tooltip${submission.id}`} >Duplicate</Tooltip>
        </button>
        <button
          data-tooltip-id={`delete-tooltip${submission.id}`}
          onClick={() => removeSubmission(submission)}
          className="btn btn-link"
          style = {{padding: '1px 4px 1px 8px'}}
        >
          <img
            id="headerImg"
            src={"/file-delete.svg"}
            style = {{width: '20px'}}
          />
          <Tooltip id={`delete-tooltip${submission.id}`} >Delete</Tooltip>
        </button>
      </td>
    </tr>
  );
};

export default SubmissionRow;
