import React, { Fragment, useState, useEffect } from "react";
import moment from 'moment';
import Select from "react-select";

import Api from "../../services/Api";
import Utilities from "../../services/Utilities";
import ModalPage from "../ui/ModalPage";
import DivWithErrorHandling from "../ui/DivWithErrorHandling";
import SubmissionBudget from "./modal/SubmissionBudget";
import UpdateBudget from "./modal/UpdateBudget";
import BudgetTable from "./tables/budget/BudgetTable";
import useModal from "../../global/useModal";
import useBudget from "../../global/useBudget";
import { initialState } from "../../global/useBudget/index";
import yearOption from "../../data/yearOption";
import { setGlobalState } from "../../global/useBudget/actions";
import { set } from "lodash";
import SelectPickerField from "../ui/SelectPickerField";

const Budget = () => {
  const [modalState, modalAction] = useModal();
  const [budgetState, budgetAction] = useBudget();
  const [isFetching, setIsFetching] = useState(false);
  const [willFetch, setWillFetch] = useState(true);
  const [error, setError] = useState(false);
  const [selectedLegends, setSelectedLegends] = useState([]);

  useEffect(() => {
    if (!isFetching && willFetch) {
      setIsFetching(true);
      setWillFetch(false);
      getBudgetSummary();
    }
  }, [budgetState.year, selectedLegends, budgetState.keyword, isFetching, budgetState.store]);

  useEffect(() => {
    getSubmissionLegends();
    return () => {
      modalAction.setModal(false);
    };
  }, []);

  const getBudgetSummary = async () => {
    let params = getSearchParams();
    try {
      const result = await Api.getData(`budgets/budget_summary?${params}`);
      setIsFetching(false);
      if (result) {
        budgetAction.setGlobalState({ budget: result });
      }
    } catch (error) {
      console.log("Error", error);
      setError(true);
    }
  };

  const sponsorshipTypes = {
    community_event: "Community Event",
    disaster_relief: "Disaster Relief",
    equine: 'Equine',
    fair: 'Fair',
    first_responder_support: 'First Responder Support',
    livestock: 'Livestock',
    other: 'Other',
    pet: 'Pet',
    rodeo_sponsorship_type: 'Rodeo Sponsorship Type',
    youth_education: 'Youth Education',
    "4h_ffa": '4H/FFA',
  }

  const formattedLegendOptions = budgetState.legends.map((legend) => {
    return { value: legend.id, label: legend.label, color: legend.color };
  });

  const getSubmissionLegends = async (attempts = 1) => {
    try {
      const collection = await Api.getData(`submission_legends?per_page=999`);
      budgetAction.setGlobalState({ legends: collection });
    } catch (error) {
      console.log("Error", error);
      if (attempts < 3) {
        getSubmissionLegends(attempts + 1);
      }
    }
  };

  const getSearchParams = () => {
    let submissionTargetDate = "event_budget_date";
    let selectedYear;
    if (!budgetState.year) {
      // Default to current year
      selectedYear = moment().format("YYYY");
      budgetAction.setGlobalState({
        year: { value: selectedYear, label: selectedYear },
      });
    } else {
      selectedYear = budgetState.year.value;
    }

    const legendIds = selectedLegends?.map((legend) => legend.value);
    let filters = {
      ...(selectedLegends && { submission_legend_id_eq_any: legendIds, }),
      ...(budgetState.keyword && {org_name_or_event_name_cont: budgetState.keyword}), 
      ...(budgetState.store && {store_number_eq: budgetState.store}), 
      status_in: ["accepted","complete"],
      event_budget_date_gteq: `${selectedYear}-01-01T00:00:00`,
      event_event_budget_date_lteq: `${selectedYear}-12-31T23:59:59`,
    };
    let parsedSearchParams = Utilities.serializeRansackParams(filters);

    return `year=${selectedYear}&${
      parsedSearchParams ? parsedSearchParams : ""
    }`;
  };

  const setYear = (year) => {
    setWillFetch(true);
    budgetAction.setGlobalState({ year });
  }

  const setCodeState = (code) => {
    setWillFetch(true);
    setCode(code);
  }

  const keywordOnChange = (e) => {
    setWillFetch(true);
    budgetAction.setGlobalState({ keyword: e.target.value });
  }

  const exportCSV = () => {
    let params = getSearchParams();
    return `/api/v1/budgets/budget_summary.xlsx?${params}`;
  };

  const exportAbbreviatedCSV = () => {
    let params = getSearchParams();
    return `/api/v1/submissions.xlsx?per_page=999999&${params}&columns[]=Event Name&columns[]=Cost&columns[]=Month&columns[]=Store Number&columns[]=Account Code`;
  };

  const closeModal = () => {
    budgetAction.setGlobalState({ modalType: null, month: null });
    modalAction.setModal(false);
  };

  const storeOnChange = (e) => {
    setWillFetch(true);
    budgetAction.setGlobalState({ store: e.target.value });
  }

  const setLegends = (e) => {
    setWillFetch(true);
    setSelectedLegends(e);
  }

  const resetFilters = (e) => {
    setWillFetch(true);
    budgetAction.setGlobalState({ store: null, keyword: null, year: null});
    setCode(null);
  }

  return (
    <DivWithErrorHandling {...{ error }}>
      <ModalPage>
        {budgetState.modalType == "submissionDetail" && <SubmissionBudget />}
        {budgetState.modalType == "updateBudget" && (
          <UpdateBudget {...{ closeModal }} />
        )}
      </ModalPage>
      {budgetState.budget && (
        <div className="row mx-1">
          <div className="col-sm-12 col-md-12 col-lg-3 col-xl-2">
            <button className="btn btn-dark mt-2" onClick={resetFilters} style={{width: '100%'}}>
              Clear
            </button>
            <h3 className="my-2">View By Keyword</h3>
            <input className="form-control py-3 " name="keyword" placeholder="Keyword..." onChange={keywordOnChange} value={budgetState.keyword || ''}/>
            <br/>
            <h3 className="my-2">View By Store</h3>
            <input className="form-control py-3 " name="keyword" placeholder="Keyword..." onChange={storeOnChange} value={budgetState.store || ''}/>
            <br/>
            <h3 className="my-2">View By Year</h3>
            <Select
              value={budgetState.year || ''}
              onChange={setYear}
              options={yearOption}
            />
            {}
            <h3 className="mt-4 mb-2">View By Code</h3>
            <SelectPickerField
              value={selectedLegends || []}
              onChange={setLegends}
              options={formattedLegendOptions}
              isMulti={true}
            />
            <h3 className="mt-4 mb-2">Tools</h3>
            <a className="btn btn-dark mb-2" href={exportCSV()} target="_blank" style={{width: '100%'}}>
              Export CSV
            </a>
            <a className="btn btn-dark" href={exportAbbreviatedCSV()} target="_blank" style={{width: '100%'}}>
              Export Abbreviated CSV
            </a>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-9 col-xl-10">
            <BudgetTable />
          </div>
        </div>
      )}
    </DivWithErrorHandling>
  );
};

export default Budget;
