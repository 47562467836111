import React, { Fragment, useState } from "react";
import SubmissionRow from "./SubmissionRow";
import Pagination from "../../../ui/Pagination";

const SubmissionTable = ({
  submissions,
  pageInfo,
  setPageInfo,
  getForms,
  removeSubmission,
  duplicateSubmission,
  declineSubmission,
  setSubmissionSelected,
  bulkDecline,
  users,
  setAssignedTo,
  filters,
  applyFilter,
  status
}) => {
  const SortBtn = ({ val }) => {
    let icon = "fa-sort";
    if (val == "ASC") {
      icon = "fa-sort-asc";
    } else if (val == "DESC") {
      icon = "fa-sort-desc";
    }
    return (
      <span>
        <i className={`fa ${icon} pl-2`}></i>
      </span>
    );
  };

  ALL_SUBMISSIONS_COLUMNS = {
    'decline': true,
    'store_number': true,
    'zone': true,
    'event_name': true,
    'event_amount_request': false,
    'assigned_to_id': true,
    'created_at': true,
    'updated_at': false,
    'last_viewed_at': true,
    'status': true,
    'comment': true,
  }

  PENDING_SUBMISSIONS_COLUMNS = {
    'decline': true,
    'store_number': true,
    'zone': true,
    'event_name': true,
    'event_amount_request': false,
    'assigned_to_id': true,
    'created_at': true,
    'updated_at': false,
    'last_viewed_at': true,
    'status': false,
    'comment': true,
  }

  ACCEPTED_SUBMISSIONS_COLUMNS = {
    'decline': true,
    'store_number': true,
    'zone': true,
    'event_amount_request': true,
    'event_name': true,
    'assigned_to_id': true,
    'created_at': false,
    'updated_at': true,
    'last_viewed_at': true,
    'status': false,
    'comment': false,
  }

  DECLINED_SUBMISSIONS_COLUMNS = {
    'decline': false,
    'store_number': true,
    'zone': true,
    'event_amount_request': false,
    'event_name': true,
    'assigned_to_id': false,
    'created_at': true,
    'updated_at': false,
    'last_viewed_at': false,
    'status': true,
    'comment': true,
  }

  COMPLETE_SUBMISSIONS_COLUMNS = {
    'decline': false,
    'store_number': true,
    'zone': true,
    'event_amount_request': true,
    'event_name': true,
    'assigned_to_id': true,
    'created_at': true,
    'updated_at': true,
    'last_viewed_at': false,
    'status': false,
    'comment': true,
  }

  STATUS_COLUMN_MAP = {
    null: ALL_SUBMISSIONS_COLUMNS,
    undefined: ALL_SUBMISSIONS_COLUMNS,
    pending: PENDING_SUBMISSIONS_COLUMNS,
    accepted: ACCEPTED_SUBMISSIONS_COLUMNS,
    declined: DECLINED_SUBMISSIONS_COLUMNS,
    complete: COMPLETE_SUBMISSIONS_COLUMNS,
  }

  const BULK_DECLINE_STATUS_MAP = {
    null: true,
    undefined: true,
    pending: true,
    accepted: false,
    declined: false,
    complete: false,
  }

  const tableColumns = STATUS_COLUMN_MAP[status];

  return (
    <Fragment>
      <a className="btn btn-dark mb-3 float-right" href="/admin/submissions/new">
        Create Submission
      </a>
      {BULK_DECLINE_STATUS_MAP[status] && <button className="btn btn-dark mb-3 mr-3 float-right" onClick={bulkDecline}>
        Bulk Decline
      </button>}
      <Pagination pageInfo={pageInfo} setPageInfo={setPageInfo} />
      <table className="table-submission table">
        <thead className="thead-light">
          <tr>
            {tableColumns['decline'] && <th scope="col"/>}
            {tableColumns['event_name'] && <th scope="col" onClick={() => applyFilter("event_name")}>
              Event Name
              <SortBtn val={filters.event_name} />
            </th>}
            {tableColumns['store_number'] && <th scope="col" style={{minWidth: "100px"}} onClick={() => applyFilter("store_number")}>
              Store #
              <SortBtn val={filters.store_number} />
            </th>}
            {tableColumns['zone'] && <th scope="col" style={{minWidth: "100px"}} onClick={() => applyFilter("zone")}>
              Zone
              <SortBtn val={filters.zone} />
            </th>}
            {tableColumns['event_amount_request'] && <th scope="col" onClick={() => applyFilter("event_amount_request")}>
              Cost
              <SortBtn val={filters.event_amount_request} />
            </th>}
            {tableColumns['assigned_to_id'] && <th scope="col" style={{minWidth: "160px"}} onClick={() => applyFilter("assigned_to_id")}>
              Assigned To
              <SortBtn val={filters.assigned_to_id} />
            </th>}
            {tableColumns['status'] && <th scope="col" onClick={() => applyFilter("status")}>
              Status
              <SortBtn val={filters.assigned_to_id} />
            </th>}
            {tableColumns['created_at'] && <th scope="col" style={{minWidth: "120px"}} onClick={() => applyFilter("created_at")}>
              Submitted
              <SortBtn val={filters.created_at} />
            </th>}
            {tableColumns['updated_at'] && <th scope="col" onClick={() => applyFilter("updated_at")}>
              Updated
              <SortBtn val={filters.updated_at} />
            </th>}
            {tableColumns['last_viewed_at'] && <th scope="col" onClick={() => applyFilter("last_viewed_at")}>
              Viewed
              <SortBtn val={filters.last_viewed_at} />
            </th>}
            <th scope="col" className="text-center">
              Manage
            </th>
          </tr>
        </thead>
        <tbody>
          {submissions.map((submission, key) => (
            <SubmissionRow
              key={key}
              submission={submission}
              removeSubmission={removeSubmission}
              duplicateSubmission={duplicateSubmission}
              declineSubmission={declineSubmission}
              setSubmissionSelected={setSubmissionSelected}
              setAssignedTo={setAssignedTo}
              users={users}
              tableColumns={tableColumns}
            />
          ))}
        </tbody>
      </table>
      <Pagination pageInfo={pageInfo} setPageInfo={setPageInfo} />
      {submissions.length < 1 && (
        <h3 className="text-center">No items found.</h3>
      )}
    </Fragment>
  );
};

export default SubmissionTable;
