import React from 'react';
import Chart from 'chart.js/auto';
import { Pie } from 'react-chartjs-2';

const SubmissionPieChartGeneric = ({ data, title }) => {
  // Transform the data object into arrays of labels and values
  const labels = Object.keys(data);
  const values = Object.values(data);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: '# of Submissions',
        data: values,
        backgroundColor: [
          "#da3832",  
          "#e56661",  
          "#f09290",  
          "#fbbebf", 
          "#ffeaea",   
          "#A02817",  
        ],
        hoverBackgroundColor: [
          "#da3832",  
          "#e56661",  
          "#f09290",  
          "#fbbebf", 
          "#ffeaea",   
          "#A02817",  
        ],
      },
    ],
  };
  const total = values.reduce((acc, value) => acc + value, 0);
  const percentages = values.map(value => ((value / total) * 100).toFixed(2));

  return (
    <div className="chart-container mt-2">
      {title && <h2 className="text-center" style={{ color: '#da3832' }}>{title}</h2>}
      <div className="grey-chart-container" style={{ width: '80%', margin: '0 auto' }}>
        <Pie data={chartData} />
      </div>
      {/* <div className="grey-chart-container" style={{ marginTop: '60px' }}>
        <h4 style={{ color: '#da3832' }}><b>{title}</b></h4>
      </div>
      <div className="grey-chart-container" style={{ marginTop: '20px' }}>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {labels.map((label, index) => (
            <li key={index} style={{ color: '#999999', textAlign: 'center' }}>
              <b>{label}:</b> {percentages[index]}%
            </li>
          ))}
        </ul>
      </div> */}
    </div>
  );
};

export default SubmissionPieChartGeneric;