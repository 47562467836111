import React from "react";
import BudgetSubmissionData from "./BudgetSubmissionData";

const BudgetSubmissionRow = ({ data }) => {
  return (
    <tr>
      <td className="text-right"></td>
      {data &&
        Object.keys(data.months).map(month => (
          <td className="p-0" key={month}>
            {data.months[month].submissions.length > 0 &&
              data.months[month].submissions.map((req, innerIndex) => (
                <BudgetSubmissionData data={req} key={innerIndex} />
              ))}
          </td>
        ))}
      <td className="text-right"></td>
    </tr>
  );
};

export default BudgetSubmissionRow;
