import React from 'react';
import CurrencyField from '../CurrencyField';
import FieldErrorMessage from '../FieldErrorMessage';
import { buildPlaceHolder } from './Helpers';

const CustomFormCurrency = ({ responsive, name, type, placeholder, fixedPlaceholder, formik: { values, errors, touched }, callbacks, ...props }) => (
  <div className={`form-group ${responsive}`}>
    {fixedPlaceholder && <label>{buildPlaceHolder(placeholder, values)}</label>}
    <CurrencyField
      {...props}
      name={name}
      type={type}
      placeholder={!fixedPlaceholder ? buildPlaceHolder(placeholder, values) : ""}
      className={`form-control py-3 ${
        errors[name] && touched[name]
          ? "is-invalid"
          : ""
      }`}
    />
    <FieldErrorMessage
      error={errors[name]}
      touched={touched[name]}
    />
  </div>
);

export default CustomFormCurrency;