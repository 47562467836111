import * as Yup from "yup";

const budgetSchema = Yup.object().shape({
  amount: Yup.number().required("Submission amount is required"),
});

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const submissionSchema = Yup.object().shape({
  submission_type: Yup.string().required("Submission Type is required"),
  org_name: Yup.string().required("Organization Name is required"),
  contact_first_name: Yup.string().required("Contact First Name is required"),
  contact_last_name: Yup.string().required("Contact Last Name is required"),
  contact_email: Yup.string()
    .email("Contact Email address must be valid")
    .required("Contact Email address is required"),
  contact_phone_number: Yup.string().required("Contact Phone Number is required").matches(phoneRegExp, 'Phone number is not valid'),
  non_profit: Yup.string().required("Non Profit Status is required"),
  org_primary_focus: Yup.string().required("Primary Focus of Organization is Required"),
  national: Yup.string().required("National Status is required"),
  members: Yup.number().required('Number of Members is required'),
  org_status: Yup.string().required("Organization Status is required"),
  org_address: Yup.string().required("Address is required"),
  org_city: Yup.string().required("City is required"),
  store_state: Yup.string().required("TSC Store State is required"),
  store_number: Yup.string().required("TSC Store Number is required"), 
  location_partner: Yup.string().required("Location Partner is required"),
  org_state: Yup.string().required("State is required"),
  org_zip_code: Yup.string().required("Zip Code is required"),
  event_name: Yup.string().required("Event Name is required"),
  event_type: Yup.string().required("Event Type is required"),
  org_legal_name: Yup.string().required("Organization Legal Name is required"),
  event_attendance: Yup.string(),
  donation_type: Yup.string().required("Donation Type is required"),
  event_amount_request: Yup.number().required("Requested amount is required").moreThan(0, 'Requested amount must be greater than 0'),
  funds_purpose: Yup.string().when('donation_type', {
    is: 'monetary',
    then: Yup.string().required('Purpose of Contribution is required'),
    else: Yup.string().notRequired()
  }),
  event_description_of_request: Yup.string().required(
    "Description of submission is required"
  ),
  sponsored_before: Yup.string().required("Required"),
  can_pickup: Yup.string(),
  event_start_date: Yup.date().required("Required"),
  age_verify: Yup.string().required("Required"),
});

const userSchema = Yup.object().shape({
  id: Yup.number().notRequired(),
  email: Yup.string().email().required("Email is required"),
  name: Yup.string().required("Name is required"),
  assignable: Yup.boolean(),
  password: Yup.string().when('id', {
    is: undefined,
    then: Yup.string().required("Password is required"),
    else: Yup.string().notRequired()
  })
});

const acceptedSubmissionSchema = Yup.object().shape({
  store_state: Yup.string().required("TSC Store State is required"),
  store_number: Yup.string().required("TSC Store Number is required"), 
  event_name: Yup.string().required("Event Name is required"),
  donation_type: Yup.string().required("Donation Type is required"),
  event_amount_request: Yup.number().required("Requested amount is required").moreThan(0, 'Requested amount must be greater than 0'),
  event_description_of_request: Yup.string().required(
    "Description of submission is required"
  ),
  status: Yup.string().required("Status is required"),
  sponsored_before: Yup.string().required("Required"),
  event_start_date: Yup.date().required("Required"),
  event_end_date: Yup.date().required("Required"),
  submission_type: Yup.string().required("Submission Type is required"),
  org_name: Yup.string().required("Organization Name is required"),
  contact_first_name: Yup.string().required("Contact First Name is required"),
  contact_last_name: Yup.string().required("Contact Last Name is required"),
  contact_email: Yup.string()
    .email("Contact Email address must be valid")
    .required("Contact Email address is required"),
  contact_phone_number: Yup.string().required("Contact Phone Number is required").matches(phoneRegExp, 'Phone number is not valid'),
});

const pendingSubmissionSchema = Yup.object().shape({
  store_state: Yup.string().required("TSC Store State is required"),
  store_number: Yup.string().required("TSC Store Number is required"), 
  event_name: Yup.string().required("Event Name is required"),
  event_type: Yup.string().required("Event Type is required"),
  org_legal_name: Yup.string().required("Organization Legal Name is required"),
  donation_type: Yup.string().required("Donation Type is required"),
  event_amount_request: Yup.number().required("Requested amount is required").moreThan(0, 'Requested amount must be greater than 0'),
  event_description_of_request: Yup.string().required(
    "Description of submission is required"
  ),
  status: Yup.string().required("Status is required"),
  sponsored_before: Yup.string().required("Required"),
  event_start_date: Yup.date().required("Required"),
  event_end_date: Yup.date().required("Required"),
  submission_type: Yup.string().required("Submission Type is required"),
  org_name: Yup.string().required("Organization Name is required"),
  contact_first_name: Yup.string().required("Contact First Name is required"),
  contact_last_name: Yup.string().required("Contact Last Name is required"),
  contact_email: Yup.string()
    .email("Contact Email address must be valid")
    .required("Contact Email address is required"),
  contact_phone_number: Yup.string().required("Contact Phone Number is required").matches(phoneRegExp, 'Phone number is not valid'),
});

export { submissionSchema, budgetSchema, userSchema, acceptedSubmissionSchema, pendingSubmissionSchema };
