import responsive from "../../themes/Responsive";
import { states } from "../selections";

const submissionFormTemplate = (stores, getStores) => ({
  contactInformation: {
    label: "Contact Information",
    fields: [
      {
        name: "submission_type",
        type: "select",
        placeholder: "Request Type*",
        responsive: responsive.halfFormWidth,
        options: [
          { value: "charity_and_fundraising", label: "Charity and Fundraising" },
          { value: "general_sponsorship", label: "General Sponsorship" },
          { value: "partnership_submission", label: "Partnership Submission" },
          { value: "product_donation", label: "Product Donation" },
          { value: 'rodeo', label: 'Rodeo'},
          { value: "advertising", label: "Advertising" },
        ],
      },
      {
        name: "org_name",
        type: "text",
        placeholder: "Organization Name*",
        responsive: responsive.halfFormWidth,
      },
      {
        name: "contact_first_name",
        type: "text",
        placeholder: "Contact First Name*",
        responsive: responsive.halfFormWidth,
      },
      {
        name: "contact_last_name",
        type: "text",
        placeholder: "Contact Last Name*",
        responsive: responsive.halfFormWidth,
      },
      {
        name: "contact_email",
        type: "text",
        placeholder: "Contact Email Address*",
        responsive: responsive.halfFormWidth,
      },
      {
        name: "contact_phone_number",
        type: "tel",
        placeholder: "Contact Phone Number*",
        responsive: responsive.halfFormWidth,
      },
    ],
  },
  organizationInformation: {
    label: "Organization Information",
    fields: [
      {
        name: "non_profit",
        type: "select",
        placeholder: "Organization a Government Sanctioned Non-Profit?*",
        responsive: responsive.fullFormWidth,
        options: [
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]
      },
      {
        name: "org_primary_focus",
        type: "select",
        placeholder: "Primary Focus of Organization*",
        responsive: responsive.halfFormWidth,

        options: [
          { value: "animal_protection", label: "Animal Protection, Welfare & Services" },
          { value: "arts_and_humanities", label: "Arts & Humanities" },
          { value: "children_and_family", label: "Children & Family" },
          { value: "civil_rights_and_liberties", label: "Civil Rights & Liberties" },
          { value: "cultural_and_ethnic", label: "Cultural & Ethnic" },
          { value: "disabled", label: "Disabled" },
          { value: "education_and_schools", label: "Education and Schools" },
          { value: "employment_mentoring", label: "Employment, Mentoring Programs" },
          { value: "environment_and_conservation", label: "Environment & Conservation" },
          { value: "general_human_services", label: "General Human Services" },
          { value: "health_and_wellness", label: "Health & Wellness" },
          { value: "international_human_rights", label: "International Human Rights" },
          { value: "libraries", label: "Libraries" },
          { value: "medical_research", label: "Medical Research" },
          { value: "mental_health_and_crisis", label: "Mental Health & Crisis Services" },
          { value: "performing_arts", label: "Performing Arts" },
          { value: "political", label: "Political" },
          { value: "poverty_and_homelessness", label: "Poverty & Homelessness" },
          { value: "public_broadcasting", label: "Public Broadcasting" },
          { value: "public_safety", label: "Public Safety, Disaster Preparedness & Relief" },
          { value: "religious", label: "Religious" },
          { value: "renovation_and_preservation", label: "Renovation, Preservation" },
          { value: "research_institutes", label: "Research Institutes" },
          { value: "seniors", label: "Seniors" },
          { value: "shelter_and_housing", label: "Shelter, Housing" },
          { value: "veterans_and_military", label: "Veterans & Military" },
          { value: "voter_education", label: "Voter Education & Registration" },
          { value: "wildlife_conservation", label: "Wildlife Conservation" },
          { value: "women", label: "Women"},
          { value: "youth_clubs_recreation_sports", label: "Youth Clubs, Recreation & Sports" },
          { value: "youth_development", label: "Youth Development" }
        ],
      },
      {
        name: 'national',
        type: "select",
        placeholder: "Local or National?*",
        responsive: responsive.halfFormWidth,
        options: [
          { value: true, label: "National" },
          { value: false, label: "Local" }
        ]
      },
      {
        name: 'members',
        type: "number",
        placeholder: "How many members nationwide?",
        responsive: responsive.halfFormWidth,
      },
      {
        name: "non_profit_tax_id_number",
        type: "text",
        placeholder: "Enter Non-profit Tax ID Number",
        responsive: responsive.halfFormWidth,
      },
      {
        name: "org_status",
        type: "select",
        placeholder: "(IRS): Non-profit Organization Status*",
        responsive: responsive.halfFormWidth,
        options: [
          { value: "501c1", label: "501(c)(1)" },
          { value: "501c2", label: "501(c)(2)" },
          { value: "501c3", label: "501(c)(2)" },
          { value: "501d", label: "501(d)" },
          { value: "501e", label: "501(e)" },
          { value: "501f", label: "501(f)" },
          { value: "501k", label: "501(k)" },
          { value: "521a", label: "521(a)" },
          { value: "527", label: "527" },
          { value: "IT496R", label: "IT-496R" },
          { value: "not_applicable", label: "Not Applicable" },
        ],
      },
      {
        name: "org_legal_name",
        type: "text",
        placeholder: "(IRS): Organization Legal Name*",
        responsive: responsive.halfFormWidth,
      },
      {
        name: "org_address",
        type: "text",
        placeholder: "(IRS): Organization Address*",
        responsive: responsive.halfFormWidth,
      },
      {
        name: "org_city",
        type: "text",
        placeholder: "(IRS): Organization City*",
        responsive: responsive.halfFormWidth,
      },
      {
        name: "org_state",
        type: "select",
        placeholder: "(IRS): Organization State*",
        responsive: responsive.halfFormWidth,
        options: states,
      },
      {
        name: "org_zip_code",
        type: "num",
        placeholder: "(IRS): Organization Zip Code*",
        responsive: responsive.halfFormWidth,
      },
    ],
  },
  eventInformation: {
    label: "Event Information",
    fields: [
      {
        name: "store_state",
        type: "select",
        placeholder: "State of TSC Store Closest To Event*",
        responsive: responsive.halfFormWidth,
        onChange: getStores,
        options: states,
      },
      {
        name: "store_number",
        type: "select",
        placeholder: "TSC Store*",
        responsive: responsive.halfFormWidth,
        display: (formState) => (formState?.store_state?.value),
        options: stores,
      },
      { 
        name: "location_partner",
        type: "select",
        placeholder: "Does Your Group Currently Partner With This Tractor Supply Store Location?",
        responsive: responsive.halfFormWidth,
        options: [
          { value: true, label: "Yes" },
          { value: false, label: "No" }
        ]
      },
      {
        name: "event_name",
        type: "text",
        placeholder: "Name of Opportunity/Iniative/Event",
        responsive: responsive.halfFormWidth,
      },
      {
        name: "event_type",
        type: "select",
        placeholder: "Type of Event*",
        responsive: responsive.halfFormWidth,
        options: [
          { value: "auction", label: "Auction" },
          { value: "awards_banquet", label: "Awards Banquet" },
          { value: "benefit_concert", label: "Benefit Concert" },
          { value: "event_sponsorship", label: "Event Sponsorship" },
          { value: "gala_celebration", label: "Gala Celebration" },
          { value: "individual_sponsorship", label: "Individual Sponsorship" },
          { value: "sports_outing", label: "Sports Outing e.g. golf tournament" },
          { value: "telethon", label: "Telethon" },
          { value: "walkathon", label: "Walkathon" },
          { value: "10k_race", label: "10K Race" },
        ]
      },
      {
        name: "org_legal_name",
        type: "text",
        placeholder: "Organization Legal Name*",
        responsive: responsive.halfFormWidth,
      },
      {
        name: "event_attendance",
        type: "select",
        placeholder: "Expected/Historical Historical Event Attendance",
        responsive: responsive.halfFormWidth,
        options: [
          { value: "attendance_none", label: "No on-site audience" },
          { value: "0_100", label: "0-100" },
          { value: "101_250", label: "101-250" },
          { value: "251_500", label: "251-500" },
          { value: "501_1000", label: "501-1000" },
          { value: "1001_5000", label: "1001-5000" },
          { value: "5001_10000", label: "5001-10000" },
          { value: "10001_25000", label: "10001-25000" },
          { value: "25001_75000", label: "25001-75000" },
          { value: "75001_250000", label: "75001-250000" },
          { value: "250001+", label: "250001+" },
          
        ]
      },
      {
        name: "donation_type",
        type: "select",
        placeholder: "Are you seeking a monetary or product donation?*",
        responsive: responsive.halfFormWidth,
        options: [
          { value: "monetary", label: "Monetary" },
          { value: "product", label: "Product" },
        ]
      },
      {
        name: "product_donation_description",
        type: "text",
        placeholder: "What specific items are you seeking to be donated?",
        responsive: responsive.halfFormWidth,
        display: (formState) => (formState?.donation_type?.value === "product")
      },
      {
        name: "event_amount_request",
        type: "currency",
        placeholder: (formState) => (formState?.donation_type?.value == "product" ? "Product Monetary Value" : "Monetary Amount Requesting*"),
        responsive: responsive.halfFormWidth,
      },
      {
        name: "funds_purpose",
        type: "select",
        placeholder: "Primary use of Funds?*",
        responsive: responsive.halfFormWidth,
        display: (formState) => (formState?.donation_type?.value === "monetary"),
        options: [
          { value: "applied_to_operational_costs", label: "Applied to Operational Costs" },
          { value: "building_or_renovation_project", label: "Building or Renovation Project" },
          { value: "endowment_fund", label: "Endowment Fund" },
          { value: "education", label: "Education" },
          { value: "food_or_shelter_assistance", label: "Food or Shelter Assistance" },
          { value: "research_project", label: "Research Project" },
          { value: "counseling_and_outreach_programs", label: "Counseling and Outreach Programs" },
          { value: "scholarship_program", label: "Scholarship Program, Stipends or Fellowships" },
          { value: "recreational_activities", label: "Recreational Activities (Sports Programs or Competition)" },
        ]
      },
      {
        name: "event_description_of_request",
        component: "textarea",
        row: 5,
        // TODO: CHANGE PLACEHOLDER TEXT
        placeholder: "Provide a brief description or summary of your opportunity*",
        responsive: responsive.fullFormWidth,
      },
      {
        name: "sponsored_before",
        type: "select",
        placeholder: "Has TSC supported this organization or event before?*",
        responsive: responsive.halfFormWidth,
        options: [
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ],
      },
      {
        name: "can_pickup",
        type: "select",
        placeholder: "Can your organization arrange pickup at local TSC Store?",
        responsive: responsive.halfFormWidth,
        options: [
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]
      },
      {
        name: "event_start_date",
        type: "date",
        maxDateIdentifier: "event_end_date",
        placeholder: "Event Start Date*",
        responsive: responsive.halfFormWidth,
      },
      {
        name: "event_end_date",
        type: "date",
        minDateIdentifier: "event_start_date",
        placeholder: "Event End Date",
        responsive: responsive.halfFormWidth,
      },
      {
        name: "commitment_deadline",
        type: "date",
        placeholder: "Commitment Deadline",
        responsive: responsive.halfFormWidth,
      },
      {
        name: "print_add_due",
        type: "date",
        maxDateIdentifier: "print_add_due",
        placeholder: "Print Ad Due Date",
        responsive: responsive.halfFormWidth,
      },
      {
        name: "age_verify",
        type: "checkbox",
        placeholder: "I am 18 or Older",
        text: "I am 18 or Older*",
        responsive: responsive.fullFormWidth
      },
    ],
  },
  orgDocuments: {
    label: "",
    fields: [
      {
        name: "org_documents",
        type: "file",
        hasFile: false,
        multiple: true,
        responsive: responsive.fullFormWidth,
      },
    ],
  },
  legalAge: {
    label: "",
    fields: [
    ],
  },
});

export default submissionFormTemplate;
