import responsive from "../../themes/Responsive";

const userFormTemplate = {
  userInformation: {
    title: "User Information",
    fields: [
      {
        name: "id",
        type: "hidden",
        required: false,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        responsive: responsive.fullFormWidth,
        required: true,
      },
      {
        name: "assignable",
        type: "checkbox",
        text: "Assignable",
        responsive: responsive.fullFormWidth,
      },
      {
        name: "email",
        type: "text",
        placeholder: "Email",
        responsive: responsive.fullFormWidth,
        required: true,
      },
      {
        name: "password",
        type: "text",
        placeholder: "Password",
        responsive: responsive.fullFormWidth,
        required: false,
      },
    ],
  },
}

export default userFormTemplate;