import React, { useState, useEffect, useRef } from "react";

import CustomForm from "../ui/CustomForm";
import LoadingView from "../ui/LoadingView";
import UserTable from "./tables/users/UserTable";
import searchFormTemplate from "../../data/formTemplates/userSearchFormTemplate";
import Api from "../../services/Api";
import Utilities from "../../services/Utilities";

const Users = () => {
  const [pageInfo, setPageInfo] = useState({
    hasMoreLoading: false,
    page: 0,
    totalPages: null,
  });
  
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [searchFields, setSearchFields] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [filters, setFilters] = useState({
    name: null,
    email: null,
  });

  useEffect(() => {
    if (pageInfo.page == 0) {
      getUsers();
    }
  }, [pageInfo.page]);

  const parseSubmitValues = (val) => {
    var data = {};
    Object.keys(val).map((key) => {
      let type = typeof val[key];

      if (!val[key]) return false;

      if (type == "object") {
        data[key] = val[key].value;
      } else {
        data[key] = val[key];
      }
    });
    return data;
  };

  const getUsers = async () => {
    let newPage = pageInfo.page + 1;

    // If triggered by load more.
    if (newPage > 1) {
      setPageInfo((prev) => ({ ...prev, hasMoreLoading: true }));
    }


    let parsedSearchParams = getParams();
    let encoded = `page=${newPage}&per_page=15&` + parsedSearchParams;

    try {
      const { collection, total_pages, current_page } = await Api.getData(
        `users?${encoded}`
      );

      setUsers((prevSub) => [...prevSub, ...collection]);

      setPageInfo({
        hasMoreLoading: false,
        page: current_page,
        totalPages: total_pages,
      });

      if (current_page >= total_pages) {
        setHasMore(false);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setPageInfo((prev) => ({ ...prev, hasMoreLoading: false }));
      setError(true);
    }
  };

  const getParams = () => {
    let params = {
      ...(searchFields.name && {
        name_cont: searchFields.name,
      }),
      ...(searchFields.email && {
        email_cont: searchFields.email,
      })
    };

    Object.keys(filters).map((filter) => {
      if (filters[filter]) {
        params["s"] = `${filter} ${filters[filter]}`;
      }
    });

    return Utilities.serializeRansackParams(params);
  };

  const removeUser = async (user) => {
    let choice = await Utilities.alertConfirm(
      "Confirm Delete",
      `Are you sure you want to remove the user: ${user.name}?`,
      "Yes",
      "No"
    );
    if (choice) {
      try {
        const result = await Api.deleteData(`users/${user.id}`);
        searchUsers();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const searchUsers = async (values = {}) => {
    setLoading(true);
    let parsedValues = {};

    if (values) {
      parsedValues = await parseSubmitValues(values);
      setSearchFields(parsedValues);
    }
    resetUserData();
  };

  const applyFilter = (type) => {
    let newType;
    if (!filters[type] || filters[type] == "DESC") {
      newType = "ASC";
    } else {
      newType = "DESC";
    }
    let newFilters = {
      [type]: newType,
    };
    Object.keys(filters).map((filter) => {
      if (filter != type) {
        newFilters[filter] = null;
      }
    });
    setFilters({ ...newFilters });
    resetUserData();
  };

  const resetUserData = () => {
    setUsers([]);
    setHasMore(true);
    setPageInfo({
      page: 0,
      totalPages: null,
    });
  };



  return (
    <div className="container-fluid mt-2">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3">
          <div className="card">
            <div className="card-body">
              <h2>Search</h2>
              <CustomForm
                initialValues={{
                  email: "",
                  name: "",
                }}
                fixedPlaceholder={true}
                enableReinitialize={true}
                formTemplate={searchFormTemplate}
                onSubmit={searchUsers}
                btnClass="btn-block"
                btnLabel="Search"
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-9">
          <div className="card">
            <div className="card-body userContainer">
            <h1 className="mb-4">Users</h1>
              <LoadingView {...{ loading, error }}>
                <UserTable
                  {...{
                    users,
                    hasMore,
                    pageInfo,
                    getUsers,
                    removeUser,
                    filters,
                    applyFilter,
                  }}
                />
              </LoadingView>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
