import React from 'react';

const SubmissionStateList = ({props}) => {
  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h3>Total Submissions: {props.totalSubmissions}</h3>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <div style={{ marginRight: '150px' }}>
          {props.firstColumnStates.map(state => (
            <div key={state.name} style={{ marginBottom: '5px' }}>
              <b>{state.name}:</b> {state.value}
            </div>
          ))}
        </div>
        <div style={{ marginLeft: '150px' }}>
          {props.secondColumnStates.map(state => (
            <div key={state.name} style={{ marginBottom: '5px' }}>
              <b>{state.name}:</b> {state.value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubmissionStateList;