import React, { useState, useEffect } from "react";
import Chart from 'chart.js/auto';
import { Bar } from "react-chartjs-2";
import Api from "../../../services/Api";


const SubmissionBarGraph = () => {
  const [statusCounts, setStatusCounts] = useState([]);
  const [statusLabels, setStatusLabels] = useState([]);
  const [countsLoaded, setCountsLoaded] = useState(false);

  LABEL_MAP = {
    pending: "Pending",
    accepted: "Accepted",
    declined: "Declined",
    auto_declined: "Auto Declined",
    completed: "Completed",
  }

  BAR_CHART_ORDER = ["pending", "auto_declined", "declined", "accepted", "completed"];

  const getStatusCounts = async () => {
    const data = await Api.getData(`reports/status_counts`);

    const statusLabels = [];
    const statusCounts = [];
    BAR_CHART_ORDER.forEach((key) => {
      statusLabels.push(LABEL_MAP[key]);
      statusCounts.push(data[key] || 0);
    });
    setStatusLabels(statusLabels);
    setStatusCounts(statusCounts);
    setCountsLoaded(true);
  }

  useEffect(() => {
    if (!countsLoaded) {
      getStatusCounts();
    }
  }, []);

  // const suggestedMin = (Math.min(...submissionCounts) - 1 > 0) ? Math.min(...submissionCounts) - 1 : 0;
  const backgroundColors = ["#D9D9D9", "#E74C3D", "#E82049", "#2ECC71", "#53D9D9"];
  const data = {
    labels: statusLabels,
    datasets: [{
      label: 'Status Counts',
      data: statusCounts,
      backgroundColor: backgroundColors,
      borderWidth: 1,
    }],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Status (30 Day)',
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (data) {
            return "Custom Label Text:" + data.formattedValue;
          },
        },
      },
      datalabels: {
        formatter: function (value) {
           //custom money icon
          return "₺" + new Intl.NumberFormat("tr-TR").format(value);
        },
        color: "white",
        font: {
          weight: 'bold',
          size:14,
          family: 'poppins'
        },
      },
    },
  };
  return(
    <div className="chart-container">
      <Bar
          type="scatter"
          data={data}
          options={options}
        />
    </div>
  );
};

export default SubmissionBarGraph;