import React, { Fragment } from "react";
import useModal from "../../../../global/useModal";
import useBudget from "../../../../global/useBudget";
import Utilities from "../../../../services/Utilities";
import { Tooltip } from 'react-tooltip'

const BudgetSubmissionData = ({ data }) => {
  const [modalState, modalAction] = useModal();
  const [budgetState, budgetAction] = useBudget();

  const openModal = () => {
    budgetAction.setGlobalState({
      selectedSubmission: data,
      modalType: "submissionDetail"
    });
    modalAction.setModal(true);
  };

  const getColor = id => {
    let color = "";
    if (budgetState.legends) {
      let res = budgetState.legends.find(legend => {
        return legend.id == id;
      });
      if (res) {
        color = res.color;
      }
    }
    return color;
  };

  return (
    <>
      <div
        data-tooltip-id={`budget-tooltip${data.id}`}
        className="submission-data px-2"
        style={{
          backgroundColor: data.submission_legend_id
            ? getColor(data.submission_legend_id)
            : ""
        }}
        onClick={openModal}
      >
        {Utilities.formatCurrency(data.event_amount_request)}
      </div>
      <Tooltip id={`budget-tooltip${data.id}`} >{data.event_name}</Tooltip>
    </>
  );
};

export default BudgetSubmissionData;
