import React from "react";
import useGlobalHook from "use-global-hook";
import * as actions from "./actions";

export const initialState = {
  budget: null,
  year: null,
  keyword: null,
  store: null,
  month: null,
  legends: [],
  selectedSubmission: null,
  selectedBudget: null,
  modalType: null //updateBudget or submissionDetail
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
