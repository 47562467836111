import React, { Fragment, useState, useRef, useEffect, useCallback } from "react";
import { Formik, Form } from "formik";
import ErrorFocus from "./ErrorFocus";
import Checkbox from "./CustomForm/Checkbox";
import PhoneNumber from "./CustomForm/PhoneNumber";
import File from "./CustomForm/File";
import Currency from "./CustomForm/Currency";
import Field from "./CustomForm/FormField";
import ResetButton from "./CustomForm/ResetButton";
import Select from "./CustomForm/Select";
import Date from "./CustomForm/FormDate";

const CustomForm = ({
  enableReinitialize = false,
  initialValues = {},
  validationSchema = null,
  onSubmit,
  formTemplate,
  btnClass = "",
  btnLabel = "Submit",
  fixedPlaceholder = false,
  removeAttachment = () => {},
  hasErrorFocus = false,
  hasErrorMsg = "Submission Invalid. See errors below.",
  verifyRecaptcha = null,
}) => {
  const [hasValidationError, setHasValidationError] = useState(false);
  const errorRef = useRef(null);
  

  useEffect(() => {
    if (hasValidationError && errorRef && window) {
      window.scrollTo(1000, errorRef.current.offsetTop - 20);
    }
  }, [hasValidationError]);

  const onValidationError = () => {
    setHasValidationError(true);
  };

  const customOnSubmit = async (values) => {
    if (verifyRecaptcha) {
      const token = await verifyRecaptcha();
      values["g-recaptcha-response"] = token;
    }
    onSubmit(values);
  }

  const TYPE_MAP = {
    resetButton: ResetButton,
    date: Date,
    select: Select,
    checkbox: Checkbox,
    tel: PhoneNumber,
    file: File,
    currency: Currency,
    undefined: Field,
    null: Field,
    '': Field,
  }

  return (

    <Fragment>
      {hasErrorFocus && hasValidationError && (
        <div
          ref={errorRef}
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          {hasErrorMsg}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setHasValidationError(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      <Formik
        enableReinitialize={enableReinitialize}
        initialValues={initialValues ? initialValues : {}}
        onSubmit={customOnSubmit}
        validationSchema={validationSchema}
      >
        {({ values, isSubmitting, isValidating, errors, touched, resetForm }) => (
          <Form>
            {hasErrorFocus && (
              <ErrorFocus
                {...{ isSubmitting, isValidating, errors, onValidationError }}
              />
            )}
            {Object.keys(formTemplate).map((groupKey) => {
              return (
                <Fragment key={groupKey}>
                  {formTemplate[groupKey].label && (
                    <h1 className="text-center">
                      {formTemplate[groupKey].label}
                    </h1>
                  )}
                  <div className="form-row">
                    {formTemplate[groupKey].fields.map(({type, display, ...rest}, key) => {
                      if (display !== undefined && display(values) === false) { return }
                      const InputComponent = TYPE_MAP[type] || Field;
                      return (
                        <Fragment key={key}>
                          <InputComponent {...{type, fixedPlaceholder, formik: { values, errors, touched, initialValues }, callbacks: { resetForm, removeAttachment }, ...rest}} />
                        </Fragment>
                      );
                    })}
                  </div>
                </Fragment>
              );
            })}
            <div className="w-100 text-center">
              <button
                type="submit"
                className={`btn btn-dark px-4 py-2 ${btnClass}`}
                disabled={isSubmitting}
              >
                {btnLabel}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default CustomForm;
