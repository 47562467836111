import React from 'react';
import { Field } from 'formik';
import FieldErrorMessage from '../FieldErrorMessage';

const CustomFormCheckbox = ({ responsive, name, text, fixedPlaceholder, formik: { errors, touched }, callbacks, ...props }) => (
  <div className={`form-group ${responsive}`}>
    <Field
      {...props}
      name={name}
      className="form-control py-3"
    >
      {({ field }) => (
        <label>
          {text && text}
          <input
            {...field}
            className="ml-2 p-2"
            type="checkbox"
            checked={field.value ? true : false}
          />
        </label>
      )}
    </Field>
    <FieldErrorMessage
      error={errors[name]}
      touched={touched[name]}
    />
  </div>
);

export default CustomFormCheckbox;