import React from "react";

const Pagination = ({ pageInfo, setPageInfo }) => {
  const page = pageInfo.page;
  const totalPages = pageInfo.totalPages;

  const setPage = (page) => {
    setPageInfo((prev) => ({ ...prev, page: page }));
  }
  
  return(
    <nav aria-label="...">
      <ul className="pagination">
        {page == 1 && <li className="page-item disabled"><span className="page-link">First</span></li>}
        {page != 1 && <li className="page-item" onClick={() => {setPage(1)}}><a className="page-link">First</a></li> }
        {page == 1 && <li className="page-item disabled"><span className="page-link">Previous</span></li>}
        {page != 1 && <li className="page-item" onClick={() => {setPage(page - 1)}}><a className="page-link">Previous</a></li> }
        {page - 2 > 0 && <li className="page-item" onClick={() => {setPage(page - 2)}}><a className="page-link">{page - 2}</a></li> }
        {page - 1 > 0 && <li className="page-item" onClick={() => {setPage(page - 1)}}><a className="page-link">{page - 1}</a></li> }
        <li className="page-item active"><span className="page-link">{page}<span className="sr-only">(current)</span></span></li>
        {page + 1 <= totalPages && <li className="page-item" onClick={() => {setPage(page + 1)}}><a className="page-link">{page + 1}</a></li> }
        {page + 2 <= totalPages && <li className="page-item" onClick={() => {setPage(page + 2)}}><a className="page-link">{page + 2}</a></li> }
        {page < totalPages && <li className="page-item" onClick={() => {setPage(page + 1)}}><a className="page-link">Next</a></li>}
        {page == totalPages && <li className="page-item disabled"><span className="page-link">Next</span></li>}
        {page < totalPages && <li className="page-item" onClick={() => {setPage(totalPages)}}><a className="page-link">Last</a></li>}
        {page == totalPages && <li className="page-item disabled"><span className="page-link">Last</span></li>}
      </ul>
    </nav>
  );
}

export default Pagination;