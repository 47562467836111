import React, { useState, useEffect } from "react";
import CustomForm from "../../ui/CustomForm";
import GoBack from "../../ui/GoBack";
import ModalPage from "../../ui/ModalPage";
import UpdateSuccessful from "../../ui/UpdateSuccessful";
import updateFormTemplate from "../../../data/formTemplates/updateFormTemplate";
import acceptedFormTemplate from "../../../data/formTemplates/acceptedFormTemplate";
import pendingFormTemplate from "../../../data/formTemplates/pendingFormTemplate";
import { submissionSchema, acceptedSubmissionSchema, pendingSubmissionSchema } from "../../../data/validationSchema";
import Api from "../../../services/Api";
import Utilities from "../../../services/Utilities";
import useModal from "../../../global/useModal";
import WithLoading from "../../../hoc/WithLoading";
import _ from 'lodash';

const UpdateSubmissionForm = ({ submission }) => {
  const [modalState, modalAction] = useModal();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [submissionData, setSubmissionData] = useState({});
  const [nextSubmission, setNextSubmission] = useState({});
  const [users, setUsers] = useState([]);

  FORM_TEMPLATE = {
    default: updateFormTemplate,
    accepted: acceptedFormTemplate,
    pending: pendingFormTemplate,
    declined: acceptedFormTemplate,
    auto_declined: acceptedFormTemplate,
    undefined: updateFormTemplate,
    complete: updateFormTemplate,
  }

  FORM_VALIDATION = {
    default: submissionSchema,
    accepted: acceptedSubmissionSchema,
    pending: pendingSubmissionSchema,
  }

  const computedSubmissionSchema = FORM_VALIDATION[submission.status] || FORM_VALIDATION.default;

  const computeFormTemplate = (users) => (
    FORM_TEMPLATE[submission.status](users) || FORM_TEMPLATE.default(users)
  );

  const [formTemplate, setFormTemplate] = useState(computeFormTemplate(submission.status));

  useEffect(() => {
    getSubmission();
  }, []);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getNextSubmission();
  }, []);


  const getUsers = async () => {
    try {
      const { collection } = await Api.getData("users?q[assignable_eq]=true");
      const users = collection.map((user) => ({value: user.id, label: user.name}));
      setUsers(users);
      setFormTemplate(computeFormTemplate(users));
    } catch (error) {
      console.log(error);
    }
  }

  SUBMISSION_KEY_MAP = {
    accepted: [
      "store_state",
      "store_number",
      "event_name",
      "event_type",
      "org_legal_name",
      "donation_type",
      "product_donation_description",
      "event_amount_request",
      "event_description_of_request",
      "event_description_of_opportunity",
      "sponsored_before",
      "event_start_date",
      "event_end_date",
      "event_budget_date",
      "status",
      "assigned_to_id",
      "notes",
      "submission_type",
      "org_name",
      "contact_first_name",
      "contact_last_name",
      "contact_email",
      "contact_phone_number",
      "account_codes",
      'comment',
      'sponsorship_type',
      'documents',
      'vendor_number',
    ],
    pending: [
      "store_state",
      "store_number",
      "event_name",
      "event_type",
      "org_legal_name",
      "donation_type",
      "product_donation_description",
      "event_amount_request",
      "event_description_of_request",
      "sponsored_before",
      "event_start_date",
      "event_end_date",
      "status",
      "assigned_to_id",
      "notes",
      "submission_type",
      "org_name",
      "contact_first_name",
      "contact_last_name",
      "contact_email",
      "contact_phone_number",
      "account_codes",
      'comment',
      'sponsorship_type',
      'documents',
    ]
  }

  const getSubmission = async () => {
    try {
      setLoading(true);
      const result = await Api.getData(`submissions/${submission.id}`);
      let tmpSubmission = result;
      tmpSubmission["documents"] = result.org_documents;
      delete tmpSubmission.org_documents;
      tmpSubmission["assigned_to_id"] = result.assigned_to_id;
      if (!!SUBMISSION_KEY_MAP[submission.status]) {
        tmpSubmission = _.pick(tmpSubmission, SUBMISSION_KEY_MAP[submission.status]);
      }
      setSubmissionData(tmpSubmission);
      setLoading(false);
    } catch (error) {
      setError(true);
    }
  };

  const getNextSubmission = async () => {
    try {
      const nextSubmission = await Api.getData(`submissions/${submission.id}/get_next_submission`);
      setNextSubmission(nextSubmission);
    } catch (error) {
      setError(true);
    }
  };

  const removeAttachment = async index => {
    let choice = await Utilities.alertConfirm(
      "Confirm Remove",
      `Are you sure you want to remove attachment?`,
      "Yes",
      "No"
    );
    if (choice) {
      try {
        const result = await Api.patchData(`submissions/${submission.id}`, {
          remove_org_documents_by_ids: `${index}`
        });
        let tmpSubmission = result;
        tmpSubmission["documents"] = result.org_documents;
        delete tmpSubmission.org_documents;
        setSubmissionData(tmpSubmission);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const patchUrlMap = {
    accepted: "accepted_update",
    pending: "pending_update",
  }

  const onSubmit = async values => {
    let choice = await Utilities.alertConfirm(
      "Confirm Update",
      `Are you sure you want to update submission?`,
      "Yes",
      "No"
    );
    if (choice) {
      const { file, ...rest } = values;
      let tmpValues = await { org_documents: file, ...rest };
      let parsedValue = await parseSubmitValues(tmpValues);
      const patchUrl = !!patchUrlMap[submission.status] ? `submissions/${submission.id}/${patchUrlMap[submission.status]}` : `submissions/${submission.id}`;
      try {
        const result = await Api.patchData(
          patchUrl,
          parsedValue,
          { "Content-Type": "multipart/form-data" }
        );
        let tmpSubmission = result;
        tmpSubmission["documents"] = result.org_documents;
        delete tmpSubmission.org_documents;
        setSubmissionData(tmpSubmission);
        modalAction.setModal(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const parseSubmitValues = val => {
    var data = new FormData();
    Object.keys(val).map(key => {
      let type = typeof val[key];
      if (type == "object" && key != "org_documents" && val[key] != null) {
        if (val[key][0]) {
          val[key].forEach((v) => { data.append(`${key}[]`, v.value || v); });
        } else {
          data.append(key, val[key].value);
        }
      } else {
        if (key == "org_documents") {
          if (val[key] != undefined && val[key].length > 0) {
            val[key].forEach(file => {
              data.append(`${key}[]`, file);
            });
          }
        } else {
          if (val[key] != undefined && val[key] != null) {
            data.append(key, val[key]);
          }
        }
      }
    });
    return data;
  };

  const UseLoading = WithLoading(CustomForm, loading, error, {
    removeAttachment: removeAttachment,
    initialValues: submissionData,
    validationSchema: computedSubmissionSchema,
    formTemplate: formTemplate,
    onSubmit: onSubmit,
    fixedPlaceholder: true,
    btnLabel: "Update"
  });

  return (
    <div className="my-3">
      <ModalPage>
        <UpdateSuccessful
          desc="Submission was successfully updated!"
          cb={() => {
            modalAction.setModal(false);
            window.open(`/admin/submissions/${nextSubmission.id}/edit`, "_self");
          }}
        />
      </ModalPage>
      <GoBack cb={() => window.history.back()} />
      <UseLoading />
    </div>
  );
};

export default UpdateSubmissionForm;
