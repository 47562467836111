import React from 'react';
import { buildPlaceHolder } from './Helpers';
import DatePickerField from '../DatePickerField';
import FieldErrorMessage from '../FieldErrorMessage';

const CustomFormDate = ({
  minDateIdentifier,
  maxDateIdentifier,
  placeholder,
  fixedPlaceholder,
  responsive,
  name,
  formik: {
    values,
    errors,
    touched,
  },
  callbacks,
  ...props
}) => {
  let min =
    minDateIdentifier && values[minDateIdentifier]
      ? values[minDateIdentifier]
      : "2015/01/01";
  let max =
    maxDateIdentifier && values[maxDateIdentifier]
      ? values[maxDateIdentifier]
      : "2030/01/01";
  return (
    <div className={`form-group ${responsive}`}>
      <label className="w-100">
        { buildPlaceHolder(placeholder, values) }
      </label>
      <DatePickerField
          {...props}
          name={name}
          minDate={new Date(moment(min))}
          maxDate={new Date(moment(max))}
          className={`form-control py-3 ${
            errors[name] && touched[name]
              ? "is-invalid"
              : ""
          }`}
        />
      <FieldErrorMessage
        error={errors[name]}
        touched={touched[name]}
      />
    </div>
  );
};

export default CustomFormDate;