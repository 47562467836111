import responsive from "../../themes/Responsive";

const STATE_OPTIONS_ARRAY = [
  {value: 'AZ', label: 'Arizona'},
  {value: 'AL', label: 'Alabama'},
  {value: 'AK', label: 'Alaska'},
  {value: 'AR', label: 'Arkansas'},
  {value: 'CA', label: 'California'},
  {value: 'CO', label: 'Colorado'},
  {value: 'CT', label: 'Connecticut'},
  {value: 'DE', label: 'Delaware'},
  {value: 'FL', label: 'Florida'},
  {value: 'GA', label: 'Georgia'},
  {value: 'HI', label: 'Hawaii'},
  {value: 'ID', label: 'Idaho'},
  {value: 'IL', label: 'Illinois'},
  {value: 'IN', label: 'Indiana'},
  {value: 'IA', label: 'Iowa'},
  {value: 'KS', label: 'Kansas'},
  {value: 'KY', label: 'Kentucky'},
  {value: 'LA', label: 'Louisiana'},
  {value: 'ME', label: 'Maine'},
  {value: 'MD', label: 'Maryland'},
  {value: 'MA', label: 'Massachusetts'},
  {value: 'MI', label: 'Michigan'},
  {value: 'MN', label: 'Minnesota'},
  {value: 'MS', label: 'Mississippi'},
  {value: 'MO', label: 'Missouri'},
  {value: 'MT', label: 'Montana'},
  {value: 'NE', label: 'Nebraska'},
  {value: 'NV', label: 'Nevada'},
  {value: 'NH', label: 'New Hampshire'},
  {value: 'NJ', label: 'New Jersey'},
  {value: 'NM', label: 'New Mexico'},
  {value: 'NY', label: 'New York'},
  {value: 'NC', label: 'North Carolina'},
  {value: 'ND', label: 'North Dakota'},
  {value: 'OH', label: 'Ohio'},
  {value: 'OK', label: 'Oklahoma'},
  {value: 'OR', label: 'Oregon'},
  {value: 'PA', label: 'Pennsylvania'},
  {value: 'RI', label: 'Rhode Island'},
  {value: 'SC', label: 'South Carolina'},
  {value: 'SD', label: 'South Dakota'},
  {value: 'TN', label: 'Tennessee'},
  {value: 'TX', label: 'Texas'},
  {value: 'UT', label: 'Utah'},
  {value: 'VT', label: 'Vermont'},
  {value: 'VA', label: 'Virginia'},
  {value: 'WA', label: 'Washington'},
  {value: 'WV', label: 'West Virginia'},
  {value: 'WI', label: 'Wisconsin'},
  {value: 'WY', label: 'Wyoming'}
];

const searchFormTemplate = (statusColumnMap) => {
  const searchFormTemplate = {
    filters: {
      label: null,
      fields: [{
        text: "Clear",
        type: "resetButton",
        className: "btn btn-dark w-100 mb-2 py-2",
      }]
    }
  }

  if (statusColumnMap['keyword']) {
    searchFormTemplate.filters.fields.push({
      name: "keyword",
      type: "text",
      placeholder: "Enter Keywords",
      responsive: responsive.searchWidth,
    })
  }

  if (statusColumnMap['store_number']) {
    searchFormTemplate.filters.fields.push({
      name: "store_number",
      type: "text",
      placeholder: "Store Number",
      responsive: responsive.searchWidth,
    })
  }

  if (statusColumnMap['state']) {
    searchFormTemplate.filters.fields.push({
      name: "state",
      type: "select",
      placeholder: "State",
      responsive: responsive.searchWidth,
      options: STATE_OPTIONS_ARRAY,
    })
  }

  if (statusColumnMap['cost']) {
    searchFormTemplate.filters.fields.push({
      name: "costMin",
      type: "currency",
      placeholder: "Cost Min",
      responsive: responsive.searchWidth,
    })

    searchFormTemplate.filters.fields.push({
      name: "costMax",
      type: "currency",
      placeholder: "Cost Max",
      responsive: responsive.searchWidth,
    })
  }

  if (statusColumnMap['start_date']) {
    searchFormTemplate.filters.fields.push({
      name: "start_date",
      type: "date",
      maxDateIdentifier: "end_date",
      placeholder: "Start Date",
      responsive: responsive.searchWidth,
    })
  }

  if (statusColumnMap['end_date']) {
    searchFormTemplate.filters.fields.push({
      name: "end_date",
      type: "date",
      minDateIdentifier: "start_date",
      placeholder: "End Date",
      responsive: responsive.searchWidth,
    })
  }

  if (statusColumnMap['source']) {
    searchFormTemplate.filters.fields.push({
      name: 'source',
      type: 'select',
      responsive: responsive.searchWidth,
      placeholder: "Source",
      isMulti: true,
      options: [
        { value: 'cmtk', label: 'CMTK', color: 'red' },
        { value: 'public_submission', label: 'Public', color: 'blue' },
      ]
    })
  }

  if (!!statusColumnMap['status']) {
    const statusOptions = [
      { value: 'declined', label: 'Declined', color: 'green' },
      { value: 'auto_declined', label: 'Auto Declined', color: 'black' },
    ]
    if (statusColumnMap['status'] == 'all') {
      statusOptions.push({ value: 'pending', label: 'Pending', color: 'red' })
      statusOptions.push({ value: 'accepted', label: 'Accepted', color: 'blue' })
    }
    searchFormTemplate.filters.fields.push({
      name: 'status',
      type: 'select',
      responsive: responsive.searchWidth,
      placeholder: "Status",
      isMulti: true,
      options: statusOptions,
    })
  }
  return searchFormTemplate;
};

export default searchFormTemplate;
