import React, { Fragment, useEffect, useState } from "react";
import UserRow from "./UserRow";
import ModalPage from "../../../ui/ModalPage";
import UserForm from "./UserForm";
import useModal from "../../../../global/useModal";

const UserTable = ({
  users,
  hasMore,
  pageInfo,
  getUsers,
  removeUser,
  filters,
  applyFilter,
}) => {
  const [modalState, modalAction] = useModal();
  const [toUpdateUser, setToUpdateUser] = useState(null);
  const [requestMethod, setRequestMethod] = useState("POST");

  useEffect(() => {
    return () => {
      modalAction.setModal(false);
    };
  }, []);

  const SortBtn = ({ val }) => {
    let icon = "fa-sort";
    if (val == "ASC") {
      icon = "fa-sort-asc";
    } else if (val == "DESC") {
      icon = "fa-sort-desc";
    }
    return (
      <span>
        <i className={`fa ${icon} pl-2`}></i>
      </span>
    );
  };

  const updateUser = (user) => {
    setToUpdateUser(user);
    modalAction.setModal(true);
    setRequestMethod("PATCH");
  }

  const clearUpdateUser = () => {
    setToUpdateUser(null);
    setRequestMethod("POST");
  }

  const createUserClick = () => { modalAction.setModal(true); };

  return (
    <Fragment>
      <ModalPage closeCallback={clearUpdateUser}>
        <UserForm requestMethod={requestMethod} toUpdateUser={toUpdateUser} />
      </ModalPage>
      <button className="btn btn-dark mb-3 float-right" onClick={createUserClick}>
        Create User
      </button>
      <table className="table-submission table">
        <thead className="thead-light">
          <tr>
            <th scope="col" onClick={() => applyFilter("org_name")}>
              Name
              <SortBtn val={filters.org_name} />
            </th>
            <th scope="col" onClick={() => applyFilter("created_at")}>
              Email
              <SortBtn val={filters.created_at} />
            </th>
            <th scope="col" onClick={() => applyFilter("created_at")}>
              Assignable
              <SortBtn val={filters.created_at} />
            </th>
            <th scope="col" className="text-center">
              Manage
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, key) => (
            <UserRow
              key={key}
              user={user}
              removeUser={removeUser}
              updateUser={updateUser}
            />
          ))}
        </tbody>
      </table>

      {pageInfo.page > 0 && hasMore && (
        <div className="w-100 text-center">
          <button
            type="button"
            onClick={getUsers}
            className="btn btn-outline-dark mx-2 w-50"
            disabled={pageInfo.hasMoreLoading}
          >
            {!pageInfo.hasMoreLoading ? "Load More" : "Loading..."}
          </button>
        </div>
      )}

      {users.length < 1 && (
        <h3 className="text-center">No items found.</h3>
      )}
    </Fragment>
  );
};

export default UserTable;
