import React, { useEffect } from "react";
import { useField, useFormikContext } from "formik";
import SelectPickerField from './SelectPickerField';

const FormikSelectPickerField = ({ onChange, isMulti, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  useEffect(() => {
    if (props.value) {
      setFieldValue(field.name, props.value);
    }
  }, []);

  const onChangeValue = (val) => {
    setFieldValue(field.name, val);
    if (!!onChange) { onChange(val) }
  }

  const computeValue = () => {
    if (isMulti) {
      return !!props.options?.find && field.value && field.value.length > 0
        ? field.value.map((field) => (props.options.find(option => option.value === field.value || option.value === field)))
        : [];
    } else {
      return !!props.options?.find && (field.value || field.value === false || field?.value?.value || field?.value?.value === false)
        ? props.options.find(option => option.value === field.value || option.value === field?.value?.value) || null
        : null
    }
  } 

  return (
    <SelectPickerField
      {...field}
      {...props}
      isMulti={isMulti}
      value={computeValue()}
      onChange={onChangeValue}
    />
  );
};

export default FormikSelectPickerField;
