import React from "react";
import { Field } from "formik";
import FieldErrorMessage from "../FieldErrorMessage";
import { buildPlaceHolder } from "./Helpers";

const CustomFormField = ({responsive, name, type, placeholder, fixedPlaceholder, formik: { values, errors, touched }, callbacks, ...props}) => {
  return (
  <div className={`form-group ${responsive}`}>
    {fixedPlaceholder && <label>{buildPlaceHolder(placeholder, values)}</label>}
    <Field
      {...props}
      name={name}
      type={type}
      value={values[name] || ""}
      placeholder={!fixedPlaceholder ? buildPlaceHolder(placeholder, values) : ""}
      className={`form-control py-3 ${
        errors[name] && touched[name]
          ? "is-invalid"
          : ""
      }`}
    />
    <FieldErrorMessage
      error={errors[name]}
      touched={touched[name]}
    />
  </div>
)};

export default CustomFormField;