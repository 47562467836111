import React from 'react';
import FileTable from '../FileTable';
import FileUploadField from '../FileUploadField';

const CustomFormFile = ({ responsive, callbacks: { removeAttachment }, fixedPlaceholder, hasFile, name, type, formik: { initialValues }, ...props }) => (
  <div className={`form-group ${responsive}`}>
    {!hasFile && (
      <div className="row px-4">
        <div className="w-75 d-flex align-items-center">
          <p>
            Please upload a copy of your organization's
            tax-exempt letter <br /> including the tax
            identification number.
          </p>
        </div>
        <div className="w-25">
          <FileUploadField
            {...{ name, type, ...props }}
            id="custom-file-input"
            className="w-100 py-3"
          />
        </div>
      </div>
    )}
    {hasFile && (
      <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6 offset-md-2 offset-lg-3 offset-xl-3">
        <h4>Attachments</h4>
        <FileTable
          {...{ removeAttachment }}
          files={
            initialValues["documents"]
              ? initialValues["documents"]
              : []
          }
        />
        <FileUploadField
          {...{ name, type, ...props }}
          id="custom-file-input"
          className="w-100 py-3"
        />
      </div>
    )}
  </div>
);

export default CustomFormFile;