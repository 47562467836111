import React from "react";

const CustomFormResetButton = ({callbacks: { resetForm }, text, fixedPlaceholder, formik: { initialValues }, ...props}) => (
  <button
    onClick={() => resetForm({values: initialValues})}
    {...props}
  >
    {text}
  </button>
);

export default CustomFormResetButton;