import React from 'react';
import SelectPickerField from "../FormikSelectPickerField";
import FieldErrorMessage from "../FieldErrorMessage";
import { buildPlaceHolder } from "./Helpers";

const CustomFormSelect = ({
  name,
  placeholder,
  fixedPlaceholder,
  responsive,
  formik: {
    values,
    errors,
    touched,
    initialValues
  },
  callbacks,
  ...props
}) => (
  <div className={`form-group ${responsive}`}>
    {fixedPlaceholder && <label>{buildPlaceHolder(placeholder, values)}</label>}
    <SelectPickerField
      {...props}
      name={name}
      placeholder={buildPlaceHolder(placeholder, values)}
      fixedPlaceholder={fixedPlaceholder}
      styles={{
        control: (styles) => ({
          ...styles,
          borderColor:
            errors[name] && touched[name]
              ? "red"
              : styles.borderColor,
        }),
      }}
      value={
        initialValues[name] != undefined
          ? initialValues[name]
          : null
      }
    />
    <FieldErrorMessage
      error={errors[name]}
      touched={touched[name]}
    />
  </div>
)

export default CustomFormSelect;