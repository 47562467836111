export default {
  submission_type: "",
  org_name: "",
  contact_first_name: "",
  contact_last_name: "",
  contact_email: "",
  contact_phone_number: "",
  org_primary_focus: "",
  non_profit_tax_id_number: "",
  org_status: "",
  org_legal_name: "",
  org_address: "",
  org_city: "",
  org_state: "",
  org_zip_code: "",
  event_name: "",
  event_start_date: "",
  event_end_date: "",
  commitment_deadline: "",
  event_description_of_opportunity: "",  
  event_amount_request: "",
  funds_purpose: "",
  event_description_of_request: "",
  sponsored_before: "",
  age_verify: "",
  status: "",
  event_budget_date: "",
  notes: "",
  non_profit: "",
  national: "",
  members: "",
  event_state: "",
  location_partner: "",
  event_attendance: "",
  donation_type: "",
  product_donation_description: "",
  can_pickup: "",
  print_add_due: "",
  event_type: "",
  store_state: "",
};
