import React from "react";
import { useField, useFormikContext } from "formik";
import CurrencyInput from 'react-currency-input-field';

const CurrencyField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <CurrencyInput
      // {...field}
      {...props}
      defaultValue={(field.value)}
      prefix={'$'}
      decimalsLimit={2}
      onValueChange={val => {
        if (val) {
          setFieldValue(field.name, val);
        }
      }}
    />
  );
};

export default CurrencyField;
