import React from "react";

const SubmissionHeader = () => {
  return (
    <>
      <img
        id="logoheader"
        className="logoHeader"
        src={"/tsc-logo.png"}
      />
      <img
        id="headerImg"
        className="w-100"
        src={"/tsc-sponsorship-application.png"}
      />
    </>
  );
};


export default SubmissionHeader;
