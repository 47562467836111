import React, { useEffect, useMemo, useState } from "react";
import moment from 'moment';
import Api from "../../services/Api";
import DivWithErrorHandling from "../ui/DivWithErrorHandling";
import NumberOfApprovedTables from "./tables/approved/NumberOfApprovedTables";
import SubmissionMap from "./charts/SubmissionMap";
import SubmissionPieChartGeneric from "./charts/SubmissionPieChartGeneric";
import SubmissionsYtdTable from "./tables/submissions/SubmissionsYtdTable";
import SubmissionsByAmountTable from "./tables/submissions/SubmissionsByAmountTable";
import SubmissionStateList from "./Submissions/SubmissionStateList";
import FadeLoader from "react-spinners/FadeLoader";

const stateAbbreviations = {
  "Alabama": "AL", "Alaska": "AK", "Arizona": "AZ", "Arkansas": "AR", "California": "CA", "Colorado": "CO", "Connecticut": "CT", "Delaware": "DE", "Florida": "FL",
  "Georgia": "GA", "Hawaii": "HI", "Idaho": "ID", "Illinois": "IL", "Indiana": "IN", "Iowa": "IA", "Kansas": "KS", "Kentucky": "KY", "Louisiana": "LA",
  "Maine": "ME", "Maryland": "MD", "Massachusetts": "MA", "Michigan": "MI", "Minnesota": "MN", "Mississippi": "MS", "Missouri": "MO", "Montana": "MT", "Nebraska": "NE",
  "Nevada": "NV", "New Hampshire": "NH", "New Jersey": "NJ", "New Mexico": "NM", "New York": "NY", "North Carolina": "NC", "North Dakota": "ND", "Ohio": "OH", "Oklahoma": "OK",
  "Oregon": "OR", "Pennsylvania": "PA", "Rhode Island": "RI", "South Carolina": "SC", "South Dakota": "SD", "Tennessee": "TN", "Texas": "TX", "Utah": "UT", "Vermont": "VT",
  "Virginia": "VA", "Washington": "WA", "West Virginia": "WV", "Wisconsin": "WI", "Wyoming": "WY"
};

const Stats = () => {
  const [stats, setStats] = useState({
    totalAccepted: null,
    totalSubmissions: null,
    submissionsByState: null,
    ytdStats: null,
    ytdType: null,
  });
  // const [summary, setSummary] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchTotalAccepted();
  }, []);

  useEffect(() => {
    fetchTotalSubmissions();
  }, []);

  /*
  useEffect(() => {
    fetchSummary();
  }, []);
  */

  useEffect(() => {
    fetchYtdStats();
  }, []);

  useEffect(() => {
    fetchYtdType();
  }, []);

  useEffect(() => {
    fetchSubmissionsByState();
  }, []);

  const fetchTotalAccepted = async () => {
    try {
      const yearToday = moment().format("YYYY");
      const yearInterval = 1;
      const yearStart = yearToday - yearInterval;
      const totalAccepted = await Api.getData(`submission_stats?&per_page=999&exclude_empty_years=true&q[status_eq]=accepted&year_start=${yearStart}&year_end=${yearToday}`);
      setStats(prevStats => ({ ...prevStats, totalAccepted: totalAccepted.collection }));
    } catch (error) {
      console.error("Error fetching total accepted:", error);
      setError(true);
    }
  };

  const fetchTotalSubmissions = async () => {
    try {
      const yearToday = moment().format("YYYY");
      const yearInterval = 1;
      const yearStart = yearToday - yearInterval;
      const totalSubmission = await Api.getData(`submission_stats?per_page=999&exclude_empty_years=true&year_start=${yearStart}&year_end=${yearToday}`);
      setStats(prevStats => ({ ...prevStats, totalSubmissions: totalSubmission.collection }));
    } catch (error) {
      console.error("Error fetching total submissions:", error);
      setError(true);
    }
  };

  /*
  const fetchSummary = async () => {
    try {
      const yearToday = moment().format("YYYY");
      const summary = await Api.getData(`submission_stats/summary?year_start=${yearToday}&year_end=${yearToday}`);
      setSummary(summary);
    } catch (error) {
      console.error("Error fetching summary:", error);
      setError(true);
    }
  };
  */

  const fetchYtdStats = async () => {
    try {
      const ytdStats = await Api.getData(`submissions/ytd_stats`);
      setStats(prevStats => ({ ...prevStats, ytdStats }));
    } catch (error) {
      console.error("Error fetching YTD stats:", error);
      setError(true);
    }
  };

  const fetchYtdType = async () => {
    try {
      const ytdType = await Api.getData(`submissions/submissions_by_type_ytd`);
      setStats(prevStats => ({ ...prevStats, ytdType }));
    } catch (error) {
      console.error("Error fetching YTD type:", error);
      setError(true);
    }
  };

  const fetchSubmissionsByState = async () => {
    try {
      const submissionsByState = await Api.getData(`submissions/submissions_by_state`);
      const filteredSubmissionsByState = Object.fromEntries(
        Object.entries(submissionsByState).filter(([key]) => key)
      );
      setStats(prevStats => ({ ...prevStats, submissionsByState: filteredSubmissionsByState }));
    } catch (error) {
      console.error("Error fetching submissions by state:", error);
      setError(true);
    }
  };

  // Memoize the submission state list props to avoid unnecessary recalculations
  const submissionStateListProps = useMemo(() => {
    const totalAcceptedSubmissions = Object.values(stats.totalAccepted || {}).reduce((acc, value) => acc + value, 0);
    const stateEntries = Object.entries(stateAbbreviations).map(([name, abbreviation]) => ({
      name,
      value: stats.submissionsByState ? stats.submissionsByState[abbreviation] || 0 : 0
    }));
    const firstColumnStates = stateEntries.slice(0, 25);
    const secondColumnStates = stateEntries.slice(25);

    return {
      totalAcceptedSubmissions,
      firstColumnStates,
      secondColumnStates
    };
  }, [stats.totalAccepted, stats.submissionsByState]);

  const renderLoader = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
   <FadeLoader 
      color={"#DA3832"} 
      height={30} 
      width={5} 
      radius={20} 
      margin={40} 
    />    
    </div>
  );
  return (
    <DivWithErrorHandling {...{ error }}>
      <div className="mx-2"style={{ position: 'relative', minHeight: '200px' }}>
      {stats.totalAccepted === null ? (
          renderLoader()
        ) : (
          <NumberOfApprovedTables
            title="Accepted Submissions By Event Date"
            data={stats.totalAccepted}
          />
        )}
      </div>
      <div className="flex-container">
        <div className="ytd-tables"style={{ position: 'relative', minHeight: '600px' }}>
        {stats.ytdStats === null ? (
            renderLoader()
          ) : (
            <>
              {stats.ytdStats.totalSubmissions && 
                <SubmissionsYtdTable
                  title="Total Submissions (YTD)"
                  data={stats.ytdStats.totalSubmissions}
                />
              }
              {stats.ytdStats.acceptedSubmissions && 
                <SubmissionsYtdTable
                  title="Accepted Submissions (YTD)"
                  data={stats.ytdStats.acceptedSubmissions}
                />
              }
              {stats.ytdStats.acceptedSubmissions && 
                <SubmissionsByAmountTable
                  title="Submissions By Amount (YTD - Business Codes 82111, 82100, 82150)"
                  data={stats.ytdStats.submissionsByAmount}
                />
              }
            </>
          )}
        </div>
        <div className="pie-chart" style={{ position: 'relative', minHeight: '600px' }}>
        {stats.ytdType === null ? (
            renderLoader()
          ) : (
            <SubmissionPieChartGeneric data={stats.ytdType} title="Submissions By Sponsorship Type"/>
          )}
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', position: 'relative', minHeight: '600px' }}>
        <div style={{ width: '70%', height: 'auto' }}>
        {stats.submissionsByState === null ? (
            renderLoader()
          ) : (
            <SubmissionMap states={stats.submissionsByState} stateAbbreviations={stateAbbreviations} />
          )}
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', position: 'relative', minHeight: '600px' }}>
      {stats.submissionsByState === null ? (
          renderLoader()
        ) : (          
          <SubmissionStateList props={submissionStateListProps} />
        )}
      </div>
    </DivWithErrorHandling>
  );
};

export default Stats;