import React, { useEffect } from "react";
import CustomForm from "../../../ui/CustomForm";
import userFormTemplate from "../../../../data/formTemplates/userFormTemplate";
import { userSchema } from "../../../../data/validationSchema";
import user from "../../../../data/user";
import Api from "../../../../services/Api";

const UserForm = ({ requestMethod, toUpdateUser }) => {
  const submitUserForm = async (values) => {
    try {
      if (requestMethod === "PATCH") {
        await Api.patchData(`users/${toUpdateUser.id}`, values, {
          "Content-Type": "application/json",
        });
        window.location.reload();
      } else {
        await Api.postData("users", values, {
          "Content-Type": "multipart/form-data",
        });
        window.location.reload();
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  initialValues = toUpdateUser || user;
  return (
    <div className="my-3">
      <CustomForm
        initialValues={initialValues}
        validationSchema={userSchema}
        formTemplate={userFormTemplate}
        onSubmit={submitUserForm}
        enableReinitialize={true}
        btnLabel="Submit"
        hasErrorFocus={true}
      />
    </div>
  );
};

export default UserForm;
