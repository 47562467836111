import React from "react";
import SubmissionLineGraph from "../charts/SubmissionLineGraph";
import SubmissionBarGraph from "../charts/SubmissionBarGraph";
import SubmissionPieChart from "../charts/SubmissionPieChart";

const SubmissionStats = ({ status }) => {
  if (status === 'accepted') { return null; }

  return(<div className="row">
    <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
      <div className="card">
        <div className="card-body">
          <SubmissionLineGraph status={status}/>
        </div>
      </div>
    </div>
    <div className="col-sm-12 col-md-12 col-lg-2 col-xl-2">
      <div className="card">
        <div className="card-body">
          <SubmissionPieChart status={status} />
        </div>
      </div>
    </div>
    <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
      <div className="card">
        <div className="card-body">
          <SubmissionBarGraph />
        </div>
      </div>
    </div>
  </div>);
}

export default SubmissionStats;